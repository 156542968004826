import React, { useEffect, useState } from "react";
import { authApiService } from "../../api/api";
import FilterBlock from "../../components/FilterBlock/FilterBlock";
import HistoryFilterModal from "../../components/modals/filters/HistoryFilterModal/HistoryFilterModal";
import ViewHistoryModal from "../../components/modals/ViewHistoryModal/ViewHistoryModal";
import Pagination from "../../components/pagination/Pagination/Pagination";
import HistoryTable from "../../components/Tables/HistoryTable";
import TableTitle from "../../components/TableTitle/TableTitle";
import { IHistory } from "../../types/history.type";
import { usersPerPage } from "../../utils/constants/page";
import { filterHistory } from "../../utils/filterUsers";
import styles from "./styles.module.scss";

const HistoryPage: React.FC = () => {
  const [clients, setClients] = useState<IHistory[]>([]);
  const [filteredClients, setFilteredClients] = useState<IHistory[]>([]);
  const [visibleClients, setVisibleClients] = useState<IHistory[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isInfoModal, setInfoModal] = useState<IHistory | null>(null);
  const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const getHistory = async () => {
    try {
      const results = await authApiService.getAllHistory();

      return results;
    } catch (err) {
      console.error(err);
    }
  };
  const changeVisibiltyClients = (clients: IHistory[]) => {
    const indexOfLastUser = activePage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    if (clients) {
      const changedClients = clients.slice(indexOfFirstUser, indexOfLastUser);
      setVisibleClients(changedClients);
    }
  };

  const getOptionsFilter = (start: Date | null, end: Date | null) => {
    let filteredHistory = [...clients];
    setStartDate(start);
    setEndDate(end);

    if (start) {
      filteredHistory = filteredHistory.filter(
        (item) => new Date(item.createdAt) >= new Date(start)
      );
    }

    if (end) {
      filteredHistory = filteredHistory.filter(
        (item) => new Date(item.createdAt) <= new Date(end)
      );
    }

    return filteredHistory;
  };

  const onFilterChange = (start: Date | null, end: Date | null) => {
    const data = getOptionsFilter(start, end);
    const filteredUsers = filterHistory(data, searchValue);
    setFilteredClients(filteredUsers);
  };

  const getFiltredClients = (clients: IHistory[]) => {
    const data = getOptionsFilter(startDate, endDate);
    const filtered = filterHistory(data, searchValue);
    setFilteredClients(filtered);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredClients.length / usersPerPage));
  }, [filteredClients]);

  useEffect(() => {
    getFiltredClients(clients);
    // setActivePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, clients]);

  useEffect(() => {
    (async () => {
      const data = await getHistory();
      if (data) {
        setClients(data);
      }
    })();
  }, []);

  useEffect(() => {
    changeVisibiltyClients(filteredClients);
    // setTotalPages(Math.ceil(users.length / usersPerPage));
    if (activePage > totalPages) {
      if (activePage === 1) {
        setActivePage(1);
      } else {
        setActivePage(totalPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, clients, totalPages, filteredClients]);
  return (
    <main className={styles.container}>
      <div className={styles.header}>
        <TableTitle title="Всього" count={clients?.length || 0} />
        <div>
          <FilterBlock
            searchValue={searchValue}
            onSearchChange={(value) => setSearchValue(value)}
            addButtonTitle="Додати клієнта"
            onPressModal={() => setIsFilterModal(true)}
          />
        </div>
      </div>

      <HistoryTable history={visibleClients} callBackInfo={setInfoModal} />
      <Pagination
        key={totalPages}
        count={totalPages}
        activePage={activePage}
        onClick={(page) => setActivePage(page)}
        onClickNext={() => setActivePage((prevState) => (prevState += 1))}
        onClickPrev={() => setActivePage((prevState) => (prevState -= 1))}
      />
      {isFilterModal && (
        <HistoryFilterModal
          onApply={onFilterChange}
          onClose={() => setIsFilterModal(false)}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {isInfoModal && (
        <ViewHistoryModal
          history={isInfoModal}
          onClose={() => setInfoModal(null)}
        />
      )}

      {/* {editSelectedClient && (
        <EditClientModal
          client={editSelectedClient}
          onClose={() => setEditSelectedClient(null)}
          onApply={onClickApplyModal}
          isInfo={isInfoModal}
        />
      )} */}
    </main>
  );
};

export default HistoryPage;

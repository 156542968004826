import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import ConfirmCodeEmailForm from "./components/AuthForms/ConfirmCodes/ConfirmCodeForgot/ConfirmCodeEmailForm";
import RegisterCodeForm from "./components/AuthForms/ConfirmCodes/RegisterCodeForm/RegisterCodeForm";
import ForgotPasswordForm from "./components/AuthForms/ForgotPasswordForm/ForgotPasswordForm";
import LoginForm from "./components/AuthForms/LoginForm/LoginForm";
import ResetPasswordForm from "./components/AuthForms/ResetPasswordForm/ResetPasswordForm";
import SignupCreatePassword from "./components/AuthForms/SignupCreatePassword/SignupCreatePassword";
import AuthLayout from "./components/AuthLayout/AuthLayout";
import MainLayout from "./components/MainLayout/MainLayout";
import BikesPage from "./pages/BikesPage/BikesPage";
import ClientsPage from "./pages/ClientsPage/ClientsPage";
import HistoryPage from "./pages/HistoryPage/HistoryPage";
import ManagementPage from "./pages/ManagementPage/ManagementPage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import ResourcesPage from "./pages/ResourcesPage/ResousrcesPage";
import StatisticsPage from "./pages/StatisticsPage/StatisticsPage";
import { useAuth } from "./providers/AuthProvider";
import { AuthPagesEnum } from "./types/authPages.type";

function App() {
  const auth = useAuth();

  useEffect(() => {
    auth.refreshAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {
          // auth.isAuthenticated
          auth.isAuthenticated ? (
            <Routes>
              <Route path={AuthPagesEnum.DEAFAULT} element={<MainLayout />}>
                <Route index element={<Navigate to="/orders" />} />
                <Route
                  path={AuthPagesEnum.STATISTICS}
                  element={<StatisticsPage />}
                />

                <Route
                  path={AuthPagesEnum.ORDERS}
                  index
                  element={<OrdersPage />}
                />

                <Route path={AuthPagesEnum.CLIENTS} element={<ClientsPage />} />

                <Route path={AuthPagesEnum.BIKES} element={<BikesPage />} />

                <Route
                  path={AuthPagesEnum.RESOURCES}
                  element={<ResourcesPage />}
                />

                <Route
                  path={AuthPagesEnum.MANAGEMENT}
                  element={<ManagementPage />}
                />
                <Route path={AuthPagesEnum.HISTORY} element={<HistoryPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<AuthLayout />}>
                <Route index element={<Navigate to="login" />} />
                <Route path="signupCode" element={<RegisterCodeForm />} />
                <Route
                  path="signupPassword"
                  element={<SignupCreatePassword />}
                />

                <Route path="login" element={<LoginForm />} />
                <Route path="forgotPassword" element={<ForgotPasswordForm />} />
                <Route path="confirmEmail" element={<ConfirmCodeEmailForm />} />
                <Route path="resetPassword" element={<ResetPasswordForm />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          )
        }
      </BrowserRouter>
    </div>
  );
}

export default App;

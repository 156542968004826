import React, { ReactNode } from "react";
import GreenButton from "../GreenButton/GreenButton";
import styles from "./styles.module.scss";

interface Props {
  title?: string;
  buttonTitle: string;
  onClick: () => void;
  children: ReactNode;
}

const GreenLinkButton: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      {props.title && <span className={styles.title}>{props.title}</span>}
      <GreenButton
        title={props.buttonTitle}
        onClick={props.onClick}
        isLink={true}
      >
        {props.children}
      </GreenButton>
    </div>
  );
};

export default GreenLinkButton;

import React, { useEffect, useState } from "react";
import { ReactComponent as AddUserIcon } from "../../../assets/icons/management/adduser.svg";
import BlueButton from "../../buttons/BlueButton/BlueButton";
import DefaultModal from "../DefaultModal/DefaultModal";
import AddUserForm from "../forms/AddUserForm/AddUserForm";
import CodeSendConfirmForm from "../forms/CodeSendConfirmForm/CodeSendConfirmForm";
import ModalTitle from "../ModalTitle/ModalTitle";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: () => void;
}

const AddUserModal: React.FC<Props> = (props) => {
  const [isSendEmail, setIsSendEmail] = useState<boolean>(false);
  const [confirmCode, setConfirmCode] = useState<string>("");

  const onApply = () => {
    props.onApply();
    props.onClose();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  return (
    <DefaultModal>
      <div className={styles.container}>
        {confirmCode ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Код реєстрації"
              subTitle="Код реєстрації згенеровано успішно! Скопіюйте цей код та поділіться ним з користувачем для завершення реєстрації."
            >
              <AddUserIcon />
            </ModalTitle>
            <CodeSendConfirmForm code={confirmCode} />
            <BlueButton title="Готово" onClick={onApply} isBigButton />
          </>
        ) : isSendEmail ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Код реєстрації успішно відправлено!"
              subTitle="Ми відправили посилання з кодом реєстрації на вказану електронну адресу. Користувач повинен перейти за посиланням для завершення реєстрації."
            >
              <AddUserIcon />
            </ModalTitle>
            <BlueButton title="Готово" onClick={onApply} isBigButton />
          </>
        ) : (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Додати користувача"
              subTitle="Додайте нового користувача до системи. Виберіть один з варіантів запрошення та заповніть необхідну інформацію."
              isLittleMargin={true}
            >
              <AddUserIcon />
            </ModalTitle>
            <AddUserForm
              onClose={props.onClose}
              onPressApply={() => {}}
              setIsSendEmail={() => setIsSendEmail(true)}
              onGetConfirmCode={(value) => setConfirmCode(value)}
            />
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default AddUserModal;

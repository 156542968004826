import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet, useLocation } from "react-router-dom";
import { authApiService } from "../../api/api";
import { ReactComponent as BikeIcon } from "../../assets/icons/nav/bike.svg";
import { ReactComponent as BurgerIcon } from "../../assets/icons/nav/burger.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/nav/cancel.svg";
import { ReactComponent as ClientsIcon } from "../../assets/icons/nav/clients.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/nav/history.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/nav/logout.svg";
import { ReactComponent as MainIcon } from "../../assets/icons/nav/main.svg";
import { ReactComponent as ManagementIcon } from "../../assets/icons/nav/management.svg";
import { ReactComponent as OrdersIcon } from "../../assets/icons/nav/orders.svg";
import { ReactComponent as ResourcesIcon } from "../../assets/icons/nav/resources.svg";

import { ReactComponent as VerifyIcon } from "../../assets/icons/user/verify.svg";

import { useAuth } from "../../providers/AuthProvider";
import { useUser } from "../../providers/UserProvider";
import { RoleType } from "../../types/role.type";
import { getPageName } from "../../utils/getPageName";
import NavButton from "./navigation/NavButton/NavButton";
import NavigationBlock from "./navigation/NavigationBlock/NavigationBlock";
import NavigationLink from "./navigation/NavigationLink/NavigationLink";
import styles from "./styles.module.scss";

const MainLayout: React.FC = () => {
  const [isOpenSideBar, setIsOpenSideBar] = useState<boolean>(false);
  const location = useLocation();
  const auth = useAuth();
  const { user, setUser } = useUser();

  const getUser = async () => {
    const response = await authApiService.getMe();
    console.log(response);
    if (!response) {
      auth.logout();
      return;
    }

    console.log(response);
    setUser(response);
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleCloseSidebar = () => {
    setIsOpenSideBar(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.empty}>Bicycle</p>
        <h2 className={styles.headerTitle}>Electrovel</h2>
        <button
          className={styles.buttonIcon}
          onClick={() => setIsOpenSideBar(!isOpenSideBar)}
        >
          {!isOpenSideBar ? <BurgerIcon /> : <CancelIcon />}
        </button>
      </div>

      {isOpenSideBar && (
        <div className={styles.backdrop} onClick={handleCloseSidebar} />
      )}

      <div className={`${styles.sidebar} ${isOpenSideBar ? styles.open : ""}`}>
        <div className={styles.sidebarInner}>
          <h1 className={styles.title}>Electrovel</h1>
          <div className={styles.navContainer}>
            <nav className={styles.nav}>
              {user?.role === RoleType.ADMIN && (
                <NavigationLink
                  onClick={handleCloseSidebar}
                  to="/statistics"
                  title="Статистика"
                >
                  <MainIcon />
                </NavigationLink>
              )}

              <NavigationBlock blockName="Оренда">
                <NavigationLink
                  onClick={handleCloseSidebar}
                  to="/orders"
                  title="Оренда"
                >
                  <OrdersIcon />
                </NavigationLink>
                <NavigationLink
                  onClick={handleCloseSidebar}
                  to="/clients"
                  title="Клієнти"
                >
                  <ClientsIcon />
                </NavigationLink>
              </NavigationBlock>
              {/* 
              <NavigationBlock blockName="Виробництво">
                <NavigationLink onClick={handleCloseSidebar} to="/production" title="Виробництво">
                  <ProductionIcon />
                </NavigationLink>
                <NavigationLink onClick={handleCloseSidebar} to="/product" title="Вироби">
                  <ProductIcon />
                </NavigationLink>
              </NavigationBlock> */}

              <NavigationBlock blockName="Склад">
                <NavigationLink
                  onClick={handleCloseSidebar}
                  to="/bikes"
                  title="Велосипеди"
                >
                  <BikeIcon />
                </NavigationLink>
                {/* <NavigationLink to="/warehouse" title="Склад">
                  <WarehouseIcon />
                </NavigationLink> */}
                <NavigationLink
                  onClick={handleCloseSidebar}
                  to="/resources"
                  title="Запчастини"
                >
                  <ResourcesIcon />
                </NavigationLink>
                <NavigationLink
                  onClick={handleCloseSidebar}
                  to="/history"
                  title="Історія"
                >
                  <HistoryIcon />
                </NavigationLink>
              </NavigationBlock>

              {user?.role === RoleType.ADMIN && (
                <NavigationLink
                  onClick={handleCloseSidebar}
                  to="/management"
                  title="Керування користувачами"
                >
                  <ManagementIcon />
                </NavigationLink>
              )}
            </nav>
            <NavButton
              onClick={() => {
                auth.logout();
              }}
              title="Вихід"
            >
              <LogoutIcon />
            </NavButton>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.userContainer}>
          <span className={styles.pageTitle}>
            {getPageName(location.pathname)}
          </span>
          <div className={styles.userInfo}>
            <div className={styles.avatarContainer}>
              <VerifyIcon className={styles.verify} />
              <img
                src={`${
                  user?.avatarUrl
                    ? user?.avatarUrl
                    : "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                }`}
                className={styles.avatar}
                alt="avatar"
              />
            </div>

            <span className={styles.userName}>
              {user?.fullName || "Manager"}
            </span>
          </div>
        </div>
        <Outlet />
        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </div>
  );
};

export default MainLayout;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authApiService } from "../../api/api";
import FilterBlock from "../../components/FilterBlock/FilterBlock";
import AddClientsModal from "../../components/modals/AddClientsModal/AddClientsModal";
import EditClientModal from "../../components/modals/EditClientModal/EditClientModal";
import Pagination from "../../components/pagination/Pagination/Pagination";
import ClientsTable from "../../components/Tables/СlientsTable";
import TableTitle from "../../components/TableTitle/TableTitle";
import { IClient } from "../../types/clients.type";
import { usersPerPage } from "../../utils/constants/page";
import { filterClients } from "../../utils/filterUsers";
import styles from "./styles.module.scss";

const ClientsPage: React.FC = () => {
  const [clients, setClients] = useState<IClient[]>([]);
  const [filteredClients, setFilteredClients] = useState<IClient[]>([]);
  const [editSelectedClient, setEditSelectedClient] = useState<IClient | null>(
    null
  );
  const [visibleClients, setVisibleClients] = useState<IClient[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isAddClientModal, setIsAddClientModal] = useState<boolean>(false);
  const [isInfoModal, setInfoModal] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get("item");
  const navigate = useNavigate();

  const removeQueryParam = (key: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
      searchParams.delete(key);
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  };

  const getResources = async () => {
    try {
      const results = await authApiService.getAllClients();

      return results;
    } catch (err) {
      console.error(err);
    }
  };
  const changeVisibiltyClients = (clients: IClient[]) => {
    const indexOfLastUser = activePage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    if (clients) {
      const changedClients = clients.slice(indexOfFirstUser, indexOfLastUser);
      setVisibleClients(changedClients);
    }
  };

  const getOptionsFilter = () => {
    let filteredClients = [...clients];
    // setRole(role);

    // if (role.value !== EmployeeType.NONE) {
    //   filteredUsers = filteredUsers.filter((user) => user.role === role.value);
    // }

    return filteredClients;
  };

  const getFiltredClients = (clients: IClient[]) => {
    const data = getOptionsFilter();
    const filtered = filterClients(data, searchValue);
    setFilteredClients(filtered);
  };

  const onClickApplyModal = async () => {
    const resp = await getResources();
    if (resp) {
      setClients(resp);
      getFiltredClients(resp);
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredClients.length / usersPerPage));
  }, [filteredClients]);

  useEffect(() => {
    getFiltredClients(clients);
    // setActivePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, clients]);

  useEffect(() => {
    (async () => {
      const data = await getResources();
      if (data) {
        setClients(data);
      }

      if (clientId) {
        const item = data?.find((res) => res.id === clientId);
        if (item) {
          setInfoModal(true);
          setEditSelectedClient(item);
          removeQueryParam("item");
        }
      }
    })();
  }, []);

  useEffect(() => {
    changeVisibiltyClients(filteredClients);
    // setTotalPages(Math.ceil(users.length / usersPerPage));
    if (activePage > totalPages) {
      if (activePage === 1) {
        setActivePage(1);
      } else {
        setActivePage(totalPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, clients, totalPages, filteredClients]);
  return (
    <main className={styles.container}>
      <div className={styles.header}>
        <TableTitle title="Всього" count={clients?.length || 0} />
        <div>
          <FilterBlock
            searchValue={searchValue}
            onSearchChange={(value) => setSearchValue(value)}
            onPressAdd={() => setIsAddClientModal(true)}
            addButtonTitle="Додати клієнта"
          />
        </div>
      </div>

      <ClientsTable
        clients={visibleClients}
        callBackInfo={setInfoModal}
        callBackEdit={setEditSelectedClient}
      />
      <Pagination
        key={totalPages}
        count={totalPages}
        activePage={activePage}
        onClick={(page) => setActivePage(page)}
        onClickNext={() => setActivePage((prevState) => (prevState += 1))}
        onClickPrev={() => setActivePage((prevState) => (prevState -= 1))}
      />
      {isAddClientModal && (
        <AddClientsModal
          onApply={onClickApplyModal}
          onClose={() => setIsAddClientModal(false)}
        />
      )}

      {editSelectedClient && (
        <EditClientModal
          client={editSelectedClient}
          onClose={() => setEditSelectedClient(null)}
          onApply={onClickApplyModal}
          isInfo={isInfoModal}
        />
      )}
    </main>
  );
};

export default ClientsPage;

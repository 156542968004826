import React from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/management/edit.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/management/info.svg";
import { IRent } from "../../types/rent.type";
import { formatDate } from "../../utils/formatDate";
import { isOneDayOrLess, isTodayOrLess } from "../../utils/getIsDate";
import styles from "./styles.module.scss";

interface Props {
  orders: IRent[];
  callBackInfo: (bool: boolean) => void;
  callBackEdit: (user: IRent) => void;
}

const OrdersTable: React.FC<Props> = (props) => {
  return (
    <table className={styles.customTable}>
      <thead>
        <tr>
          <th>Байк</th>
          <th>Клієнт</th>
          <th>Оренда від</th>
          <th>Оренда до</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.orders &&
          props.orders.map((order, index) => {
            const isNeedPay = isOneDayOrLess(order.paidTo);
            const isPaid =
              formatDate(order.dateTo) === formatDate(order.paidTo);
            const isNeedBackBike =
              isTodayOrLess(order.dateTo) && !order.isReturned;
            const unPaid =
              new Date(order.paidTo).getTime() - new Date().getTime() < 0;

            return (
              <tr
                key={order.id}
                className={
                  order.isReturned
                    ? ""
                    : isPaid
                    ? ""
                    : isNeedPay
                    ? styles.accent
                    : isNeedBackBike
                    ? styles.secondAccent
                    : unPaid
                    ? styles.thirdAccent
                    : ""
                }
              >
                <td>
                  <div className={styles.userInfo}>
                    <div className={styles.nameContainer}>
                      <span className={styles.name}>{order.bike.number}</span>
                      <span className={styles.email}>{order.bike.name}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className={styles.nameContainer}>
                    <span className={styles.email}>
                      {order.client.fullName}
                    </span>
                    <span className={styles.email}>{order.client.phone}</span>
                  </div>
                </td>
                <td className={styles.role}>{formatDate(order.dateFrom)}</td>
                <td className={styles.role}>{formatDate(order.dateTo)}</td>

                <td>
                  <div className={styles.actions}>
                    <button
                      className={styles.editBtn}
                      onClick={() => {
                        props.callBackInfo(false);
                        props.callBackEdit(order);
                      }}
                    >
                      <EditIcon />
                    </button>

                    <button
                      className={styles.infoBtn}
                      onClick={() => {
                        props.callBackInfo(true);
                        props.callBackEdit(order);
                      }}
                    >
                      <InfoIcon />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default OrdersTable;

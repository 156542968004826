import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/modal/close.svg";
import { IRent } from "../../../types/rent.type";
import DefaultModal from "../DefaultModal/DefaultModal";
import EditOrderForm from "../forms/EditOrderForm/EditOrderForm";
import ViewOrderForm from "../forms/EditOrderForm/ViewOrderForm";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: () => void;
  isInfo: boolean;
  order: IRent;
}

const EditOrderModal: React.FC<Props> = (props) => {
  const [isEdit] = useState<boolean>(!props.isInfo);
  // const [isDelete, setIsDelete] = useState<boolean>(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  const onPressApply = async () => {
    props.onApply();
    props.onClose();
  };

  const onPressApplyUpdate = async () => {
    onPressApply();
  };

  //   const onPressDelete = async () => {
  //     try {
  //       const response = await authApiService.deleteWarehouse(props.warehouse.id);

  //       if (response) {
  //         props.onApply();
  //         props.onClose();
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };

  return (
    <DefaultModal>
      <div className={styles.container}>
        {isEdit ? (
          <>
            <EditOrderForm
              order={props.order}
              onClose={() => props.onClose()}
              onApply={() => onPressApplyUpdate()}
            />
          </>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.upSide}>
                <span className={styles.name}>{props.order.name}</span>

                <button className={styles.upSideButton} onClick={props.onClose}>
                  <CloseIcon />
                </button>
              </div>
            </div>
            <ViewOrderForm
              onClose={props.onClose}
              onPressApply={onPressApply}
              order={props.order}
              onChangeStatus={props.onApply}
            />
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default EditOrderModal;

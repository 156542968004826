import { EmployeeType } from "../types/employee.type";
import { RoleType } from "../types/role.type";

export const getRole = (role: string): string => {
  switch (role) {
    case RoleType.ADMIN:
      return "Адміністратор";
    case RoleType.MASTER:
      return "Майстер";
    default:
      return "Невідомо";
  }
};

export const getEmployeeRole = (role: string): string => {
  switch (role) {
    case EmployeeType.WELDER:
      return "Зварювальник";
    default:
      return "Невідомо";
  }
};

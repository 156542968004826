import React, { ReactNode } from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  onClick: () => void;
  children: ReactNode;
}

const NavButton: React.FC<Props> = (props) => {
  return (
    <button onClick={props.onClick} className={styles.button}>
      {props.children}
      {props.title}
    </button>
  );
};

export default NavButton;

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../api/api";
import { ReactComponent as ClientIcon } from "../../../assets/icons/modal/client.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/modal/delete.svg";
import { IClient } from "../../../types/clients.type";
import DefaultModal from "../DefaultModal/DefaultModal";
import ModalDeleteButtons from "../forms/buttons/ModalDeletButtons/ModalDeleteButtons";
import EditClientForm from "../forms/EditClientForm/EditClientForm";
import ViewClientForm from "../forms/EditClientForm/ViewClientForm";
import ModalTitle from "../ModalTitle/ModalTitle";
import styles from "./styles.module.scss";

interface Props {
  client: IClient;
  onClose: () => void;
  onApply: () => void;
  isInfo: boolean;
}

const EditClientModal: React.FC<Props> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(!props.isInfo);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  const onPressApply = async () => {
    props.onApply();
    props.onClose();
  };

  const onPressDelete = async () => {
    try {
      const response = await authApiService.deleteClient(props.client.id);
      console.log(response);

      if (response) {
        props.onApply();
        props.onClose();
      }
    } catch (err: any) {
      console.error(err.message);
      toast.error(err.message);
    }
  };

  return (
    <DefaultModal>
      <div className={styles.container}>
        {isDelete ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Підтвердіть видалення клієнта"
              subTitle="Ви дійсно хочете видалити цього постачальника? Цю дію неможливо скасувати."
            >
              <DeleteIcon />
            </ModalTitle>
            <ModalDeleteButtons
              onPressApply={onPressDelete}
              onPressBack={() => {
                setIsDelete(false);
              }}
              applyTitle="Видалити"
              backTitle="Назад"
            />
          </>
        ) : isEdit ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Редагувати клієнта"
              subTitle='Редагуйте інформацію про постачальника. Внесіть необхідні зміни та натисніть "Зберегти".'
            >
              <ClientIcon />
            </ModalTitle>
            <EditClientForm
              onApply={onPressApply}
              onClose={() => setIsDelete(true)}
              client={props.client}
            />
          </>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.nameContainer}>
                <span className={styles.name}>{props.client.fullName}</span>
              </div>
            </div>
            <ViewClientForm
              onClose={props.onClose}
              onPressEdit={() => setIsEdit(true)}
              client={props.client}
            />
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default EditClientModal;

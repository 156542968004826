export function daysBetween(dateFrom: Date, dateTo: Date): number {
  // Конвертуємо обидві дати у мілісекунди
  const oneDay = 24 * 60 * 60 * 1000; // кількість мілісекунд в одному дні
  const diffInTime = dateTo.getTime() - dateFrom.getTime();

  // Підраховуємо кількість днів
  const diffInDays = Math.ceil(diffInTime / oneDay);

  // Додаємо 1, щоб врахувати обидва дні включно
  return diffInDays + 1;
}

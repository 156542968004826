import { IEmployee, IUser } from "../api/api.interface";
import { IBike } from "../types/bikes.type";
import { IClient } from "../types/clients.type";
import { IHistory } from "../types/history.type";
import { IOrder } from "../types/order.type";
import { IProduct } from "../types/product.type";
import { IProduction } from "../types/production.type";
import { IRent } from "../types/rent.type";
import { IResource } from "../types/resources.type";
import { ISelectsInput } from "../types/selects.type";
import { ISupplier } from "../types/suppliers.type";
import { IWarehouse } from "../types/warehouse.type";

export const filterUsers = (users: IUser[], searchValue: string): IUser[] => {
  if (!searchValue) return users;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return users.filter(
    (user) =>
      (user.fullName &&
        user.fullName.toLowerCase().includes(lowercasedSearchValue)) ||
      (user.email &&
        user.email.toLowerCase().includes(lowercasedSearchValue)) ||
      (user.phone &&
        user.phone.toLowerCase().includes(lowercasedSearchValue)) ||
      (user.status &&
        user.status.toLowerCase().includes(lowercasedSearchValue)) ||
      (user.role && user.role.toLowerCase().includes(lowercasedSearchValue))
  );
};

export const filterEmployees = (
  users: IEmployee[],
  searchValue: string
): IEmployee[] => {
  if (!searchValue) return users;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return users.filter(
    (user) =>
      (user.fullName &&
        user.fullName.toLowerCase().includes(lowercasedSearchValue)) ||
      (user.email &&
        user.email.toLowerCase().includes(lowercasedSearchValue)) ||
      (user.phone && user.phone.toLowerCase().includes(lowercasedSearchValue))
  );
};

export const filterSuppliers = (
  suppliers: ISupplier[],
  searchValue: string
): ISupplier[] => {
  if (!searchValue) return suppliers;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return suppliers.filter(
    (supplier) =>
      (supplier.name &&
        supplier.name.toLowerCase().includes(lowercasedSearchValue)) ||
      (supplier.email &&
        supplier.email.toLowerCase().includes(lowercasedSearchValue)) ||
      (supplier.phone &&
        supplier.phone.toLowerCase().includes(lowercasedSearchValue)) ||
      (supplier.contactFullName &&
        supplier.contactFullName.toLowerCase().includes(lowercasedSearchValue))
  );
};

export const filterResources = (
  resources: IResource[],
  searchValue: string
): IResource[] => {
  if (!searchValue) return resources;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return resources.filter(
    (resource) =>
      (resource.name &&
        resource.name.toLowerCase().includes(lowercasedSearchValue)) ||
      (resource.description &&
        resource.description.toLowerCase().includes(lowercasedSearchValue))
  );
};

export const filterWarehouse = (
  resources: IWarehouse[],
  searchValue: string
): IWarehouse[] => {
  if (!searchValue) return resources;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return resources.filter(
    (resource) =>
      resource.name &&
      resource.name.toLowerCase().includes(lowercasedSearchValue)
  );
};

// export const filterSelectItems = (
//   items: ISelectsInput[],
//   searchValue: string
// ) => {
//   if (!searchValue) return items;
//   const lowercasedSearchValue = searchValue.toLowerCase();

//   return items.filter(
//     (item) =>
//       (item.name && item.name.toLowerCase().includes(lowercasedSearchValue)) ||
//       (item.subName &&
//         item.subName.toLowerCase().includes(lowercasedSearchValue)) ||
//       (item.secondSubName &&
//         item.secondSubName.toLocaleLowerCase().includes(lowercasedSearchValue))
//   );
// };
export const filterSelectItems = (
  items: ISelectsInput[],
  searchValue: string
) => {
  if (!searchValue) return items;
  const searchWords = searchValue.toLowerCase().split(" ");

  return items.filter((item) => {
    const itemName = item.name?.toLowerCase() || "";
    const itemSubName = item.subName?.toLowerCase() || "";
    const itemSecondSubName = item.secondSubName?.toLowerCase() || "";

    return searchWords.every(
      (word) =>
        itemName.includes(word) ||
        itemSubName.includes(word) ||
        itemSecondSubName.includes(word)
    );
  });
};

export const filterClients = (
  clients: IClient[],
  searchValue: string
): IClient[] => {
  if (!searchValue) return clients;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return clients.filter(
    (client) =>
      (client.fullName &&
        client.fullName.toLowerCase().includes(lowercasedSearchValue)) ||
      (client.email &&
        client.email.toLowerCase().includes(lowercasedSearchValue)) ||
      (client.phone &&
        client.phone.toLowerCase().includes(lowercasedSearchValue))
  );
};

export const filterProducts = (
  clients: IProduct[],
  searchValue: string
): IProduct[] => {
  if (!searchValue) return clients;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return clients.filter(
    (client) =>
      (client.name &&
        client.name.toLowerCase().includes(lowercasedSearchValue)) ||
      (client.description &&
        client.description.toLowerCase().includes(lowercasedSearchValue))
  );
};

export const filterOrder = (
  resources: IOrder[],
  searchValue: string
): IOrder[] => {
  if (!searchValue) return resources;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return resources.filter(
    (resource) =>
      resource.name &&
      resource.name.toLowerCase().includes(lowercasedSearchValue)
  );
};

export const filterProductions = (
  resources: IProduction[],
  searchValue: string
): IProduction[] => {
  if (!searchValue) return resources;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return resources.filter(
    (resource) =>
      resource.order.name &&
      resource.order.name.toLowerCase().includes(lowercasedSearchValue)
  );
};

export const filterHistory = (
  clients: IHistory[],
  searchValue: string
): IHistory[] => {
  if (!searchValue) return clients;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return clients.filter(
    (client) =>
      (client.bikeName &&
        client.bikeName.toLowerCase().includes(lowercasedSearchValue)) ||
      (client.bikeNumber &&
        client.bikeNumber.toLowerCase().includes(lowercasedSearchValue))
  );
};

export const filterBikes = (clients: IBike[], searchValue: string): IBike[] => {
  if (!searchValue) return clients;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return clients.filter(
    (client) =>
      (client.number &&
        client.number.toLowerCase().includes(lowercasedSearchValue)) ||
      (client.name && client.name.toLowerCase().includes(lowercasedSearchValue))
  );
};

export const filterRent = (clients: IRent[], searchValue: string): IRent[] => {
  if (!searchValue) return clients;

  const lowercasedSearchValue = searchValue.toLowerCase();
  return clients.filter(
    (client) =>
      (client.bike.number &&
        client.bike.number.toLowerCase().includes(lowercasedSearchValue)) ||
      (client.client.fullName &&
        client.client.fullName.toLowerCase().includes(lowercasedSearchValue)) ||
      (client.bike.name &&
        client.bike.name.toLowerCase().includes(lowercasedSearchValue))
  );
};

import React from "react";
import { ReactComponent as LeftIcon } from "../../assets/icons/week/chevronLeft.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/week/chevronRight.svg";
import { formatDate } from "../../utils/formatDate";
import { getWeekStartAndEnd } from "../../utils/getWeekStartAndEnd";
import styles from "./styles.module.scss";

interface Props {
  date: Date;
  onClickLeft: () => void;
  onClickRight: () => void;
}

const WeekSelector: React.FC<Props> = (props) => {
  const date = getWeekStartAndEnd(props.date);
  return (
    <div className={styles.container}>
      <button onClick={props.onClickLeft} className={styles.button}>
        <LeftIcon />
      </button>
      <span className={styles.title}>
        {formatDate(date.startOfWeek)} - {formatDate(date.endOfWeek)}
      </span>
      <button onClick={props.onClickRight} className={styles.button}>
        <RightIcon />
      </button>
    </div>
  );
};

export default WeekSelector;

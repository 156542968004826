import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
  text: string;
  linkText: string;
  to: string;
}

const TextWithLink: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <span className={styles.text}>{props.text}</span>
      <Link to={props.to} className={styles.link}>
        {props.linkText}
      </Link>
    </div>
  );
};

export default TextWithLink;

import React, { LegacyRef, useState } from "react";
import toast from "react-hot-toast";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/modal/email.svg";
import { ReactComponent as ContactIcon } from "../../../../assets/icons/supplier/contact.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/supplier/phone.svg";

import { authApiService } from "../../../../api/api";
import { IClient, IClientCreateDto } from "../../../../types/clients.type";
import { BASE_URL } from "../../../../utils/constants/api";
import {
  formatPhoneNumber,
  unformatPhoneNumber,
} from "../../../../utils/fotmatPhoneNumber";
import SelectFileButton from "../../../buttons/SelectFileButton/SelectFileButton";
import UploadedFileButton from "../../../buttons/UploadedFileButton/UploadedFileButton";
import SmallWhiteButton from "../../../buttons/WhiteButton/SmallWhiteButton";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: () => void;
  client: IClient;
}

const EditClientForm: React.FC<Props> = (props) => {
  const [fullName, setFullName] = useState<string>(props.client.fullName);
  const [email, setEmail] = useState<string>(props.client.email);
  const [phone, setPhone] = useState<string>(
    formatPhoneNumber(props.client.phone)
  );
  const [note, setNote] = useState<string>(props.client.note);
  const [fileUrls, setFileUrls] = useState<string[]>(
    props.client.files ? props.client.files : []
  );
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const fileInputRef: LegacyRef<HTMLInputElement> | undefined =
    React.createRef();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevState) => [...prevState, file]);
    }
  };

  const onChangePhone = (value: string) => {
    const formated = formatPhoneNumber(value);
    setPhone(formated);
  };

  const onClickApply = async () => {
    const files = [...fileUrls];
    for (const file of selectedFiles) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await authApiService.uploadImage(formData);
        const indexToReplace = files.findIndex((image) =>
          image.startsWith("data:")
        );
        if (indexToReplace !== -1) {
          files[indexToReplace] = `${BASE_URL}/${response.path}`;
        } else {
          files.push(`${BASE_URL}/${response.path}`);
        }
      } catch (err: any) {
        console.error(err);
        toast.error(err.message);
      }
    }

    try {
      const body: IClientCreateDto = {
        fullName,
        email,
        phone: unformatPhoneNumber(phone),
        note,
        files,
      };
      const response = await authApiService.updateClient(props.client.id, body);
      if (response) {
        props.onApply();
      }
    } catch (err: any) {
      toast.error(err.message);
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <EditInput
          value={fullName}
          setValue={setFullName}
          isRequired
          title="Імʼя та Прізвище"
        >
          <ContactIcon />
        </EditInput>

        <EditInput
          value={email}
          setValue={setEmail}
          title="Telegram"
          isRequired={false}
        >
          <EmailIcon />
        </EditInput>
        <EditInput
          value={phone}
          setValue={onChangePhone}
          title="Номер телефону"
          isRequired={false}
        >
          <PhoneIcon />
        </EditInput>

        <EditInput
          value={note}
          setValue={setNote}
          title="Примітка"
          isRequired={false}
          isBig={true}
        />
        {selectedFiles.map((file, index) => (
          <SelectFileButton
            key={index}
            title={file.name}
            onClick={() => {
              setSelectedFiles((prevState) => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
              });
            }}
          />
        ))}
        {fileUrls.map((file, index) => (
          <UploadedFileButton
            key={file}
            file={file}
            onClick={() => {
              setFileUrls((prevState) => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
              });
            }}
          />
        ))}
        <input
          id="file-upload"
          type="file"
          accept="file/*"
          onChange={handleFileChange}
          ref={fileInputRef}
          className={styles.input}
        />
        <SmallWhiteButton
          onClick={handleButtonClick}
          title="Завантажити файл"
        />
      </div>
      <ModalBottomButtons
        onPressApply={onClickApply}
        onPressBack={props.onClose}
        isDeleteBackButton={true}
        applyTitle="Зберегти"
        backTitle="Видалити"
      />
    </div>
  );
};

export default EditClientForm;

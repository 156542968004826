import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { unAuthApiService } from "../../../api/api";
import { useAuth } from "../../../providers/AuthProvider";
import { InputEnum } from "../../../types/input.type";
import { validateEmail } from "../../../utils/validation/email";
import AuthTitle from "../../AuthTitle/AuthTitle";
import BlueButton from "../../buttons/BlueButton/BlueButton";
import WhiteButton from "../../buttons/WhiteButton/WhiteBotton";
import LoginInput from "../../inputs/LoginInput/LoginInput";
import styles from "./styles.module.scss";

const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [errorEmailMessage, setErrorEmailMessage] = useState<string>("");

  const navigation = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    setErrorEmailMessage("");
  }, [email]);

  const onChangeEmail = (value: string) => {
    setEmail(value);
  };

  const sendNewCode = async () => {
    try {
      return await unAuthApiService.sendNewCode(email);
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const onPressApply = async () => {
    const validEmail = await validateEmail(email);

    if (!validEmail.success) {
      validEmail.error && setErrorEmailMessage(validEmail.error);
      return;
    }

    const response = await sendNewCode();

    if (!response) {
      return;
    }
    navigation("/confirmEmail", { state: { email } });
  };

  return (
    <div className={styles.container}>
      <AuthTitle
        title="Забули пароль?"
        subTitle="Введіть свою електронну адресу, і ми надішлемо вам код для оновлення паролю."
      />
      <div className={styles.inputsContainer}>
        <LoginInput
          name="Email"
          isRequired
          type={InputEnum.EMAIL}
          value={email}
          onChange={onChangeEmail}
          errorMessage={errorEmailMessage}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <BlueButton
          title="Надіслати"
          onClick={onPressApply}
          disabled={email.length < 3}
        />
        <WhiteButton
          title="Назад"
          onClick={() => {
            navigation("/login");
          }}
        />
      </div>
    </div>
  );
};

export default ForgotPasswordForm;

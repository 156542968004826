import React from "react";
import { ReactComponent as NextIcon } from "../../../../../assets/icons/modal/next.svg";
import styles from "./styles.module.scss";

interface Props {
  onPressApply?: () => void;
  applyTitle: string;
  isNextIcon?: boolean;
  backTitle: string;
  onPressBack: () => void;
  isDeleteBackButton?: boolean;
  isApplyDisabled?: boolean;
  isBigModal?: boolean;
}

const ModalBottomButtons: React.FC<Props> = (props) => {
  return (
    <div
      className={`${styles.container} ${
        props.isBigModal ? styles.bigModal : ""
      }`}
    >
      <button
        onClick={props.onPressBack}
        className={`${
          props.isDeleteBackButton ? styles.deleteButton : styles.backButton
        }`}
      >
        {props.backTitle}
      </button>
      {props.onPressApply && (
        <button
          onClick={props.onPressApply}
          className={styles.applyButton}
          disabled={props.isApplyDisabled}
        >
          <span>{props.applyTitle}</span> {props.isNextIcon && <NextIcon />}
        </button>
      )}
    </div>
  );
};

export default ModalBottomButtons;

import React, { useRef, useState } from "react";

import BlueButton from "../../../buttons/BlueButton/BlueButton";
import WhiteButton from "../../../buttons/WhiteButton/WhiteBotton";
import styles from "./styles.module.scss";

interface Props {
  onPressApply: (code: string) => void;
  onPressBack?: () => void;
  code?: string | null;
}

const CodeForm: React.FC<Props> = (props) => {
  const [code, setCode] = useState<string[]>(
    props.code ? props.code.split("") : ["", "", "", "", "", ""]
  );
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (value: string, index: number) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (value && index < inputs.current.length - 1) {
        inputs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && code[index] === "") {
      if (index > 0) {
        inputs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").slice(0, 6); // беремо лише перші 6 символів
    const newCode = pastedData
      .split("")
      .map((char) => (/^\d$/.test(char) ? char : ""));
    for (let i = 0; i < 6; i++) {
      if (newCode[i]) {
        code[i] = newCode[i];
      } else {
        code[i] = "";
      }
    }
    setCode([...code]);
    if (inputs.current[pastedData.length - 1]) {
      inputs.current[pastedData.length - 1]?.focus();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {code.map((item, index) => (
          <input
            onPaste={handlePaste}
            key={index}
            value={item}
            onChange={(e) => handleChange(e.target.value, index)}
            ref={(el) => (inputs.current[index] = el)}
            className={styles.input}
            type="number"
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        ))}
      </div>
      <div className={styles.buttonsContainer}>
        {props.onPressApply && (
          <BlueButton
            title="Надіслати"
            onClick={() => props.onPressApply(code.join(""))}
            disabled={code.some((item) => item === "")}
          />
        )}
        {props.onPressBack && (
          <WhiteButton title="Назад" onClick={props.onPressBack} />
        )}
      </div>
    </div>
  );
};

export default CodeForm;

export enum AuthPagesEnum {
  DEAFAULT = "/",
  STATISTICS = "/statistics",
  ORDERS = "/orders",
  CLIENTS = "/clients",
  PRODUCTION = "/production",
  PRODUCT = "/product",
  WAREHOUSE = "/warehouse",
  RESOURCES = "/resources",
  SUPPLEIERS = "/suppliers",
  EMPLOYEES = "/employees",
  MANAGEMENT = "/management",
  HISTORY = "/history",
  BIKES = "/bikes",
}

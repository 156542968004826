import React from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
  children?: React.ReactNode;
}

const AuthLayout: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      {props.children}
      <Outlet />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default AuthLayout;

import React, { LegacyRef, useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { IUser, IUserChangeDto } from "../../../../api/api.interface";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/modal/email.svg";
import { ReactComponent as PhotoIcon } from "../../../../assets/icons/modal/emptyPhoto.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/modal/phone.svg";
import { ReactComponent as UserIcon } from "../../../../assets/icons/modal/user.svg";
import { RoleType } from "../../../../types/role.type";
import { BASE_URL } from "../../../../utils/constants/api";
import {
  formatPhoneNumber,
  unformatPhoneNumber,
} from "../../../../utils/fotmatPhoneNumber";
import { getRole } from "../../../../utils/getRole";
import SmallWhiteButton from "../../../buttons/WhiteButton/SmallWhiteButton";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import SelectInput, { SelectOption } from "../inputs/SelectInput/SelectInput";
import styles from "./styles.module.scss";

interface Props {
  user: IUser;
  onClose: () => void;
  onPressApply: (user: IUserChangeDto) => void;
}

const roleOptions: SelectOption[] = [
  { value: RoleType.ADMIN, label: getRole(RoleType.ADMIN) },
  { value: RoleType.MASTER, label: getRole(RoleType.MASTER) },
];

const EditUserForm: React.FC<Props> = (props) => {
  const [name, setName] = useState<string>(props.user.fullName);
  const [email, setEmail] = useState<string>(props.user.email);
  const [phone, setPhone] = useState<string>(
    formatPhoneNumber(props.user.phone)
  );
  const [role, setRole] = useState<SelectOption>(
    roleOptions.find((item) => item.value === props.user.role) || roleOptions[0]
  );
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<any>(props.user.avatarUrl);
  const fileInputRef: LegacyRef<HTMLInputElement> | undefined =
    React.createRef();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const removePhoto = () => {
    setSelectedImage(null);
    setPreviewUrl(null);
  };

  const onChangePhone = (value: string) => {
    const formated = formatPhoneNumber(value);
    setPhone(formated);
  };

  const selectChange = (selectedRole: SelectOption) => {
    setRole(selectedRole);
  };

  const onPressApply = async () => {
    setIsDisabled(true);
    let avatar = previewUrl;

    try {
      if (selectedImage) {
        const formData = new FormData();
        formData.append("file", selectedImage);
        const response = await authApiService.uploadImage(formData);
        console.log(response);
        avatar = `${BASE_URL}/${response.path}`;
      }
    } catch (err: any) {
      console.error(err);
      toast.error("Помилка завантаження фото. Можливо розмір файл завеликий.");
    }

    const body: any = {
      fullName: name,
      email,
      phone: unformatPhoneNumber(phone),
      avatarUrl: avatar,
    };
    if (role?.value) {
      body.role = role?.value;
    }
    props.onPressApply(body);
  };

  return (
    <div>
      <div className={styles.photoContainer}>
        {previewUrl ? (
          <img src={previewUrl} alt="resource" className={styles.image} />
        ) : (
          <PhotoIcon />
        )}

        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={fileInputRef}
          className={styles.input}
        />
        <SmallWhiteButton
          onClick={previewUrl ? removePhoto : handleButtonClick}
          title={previewUrl ? "Прибрати фото" : "Додати фото"}
        />
      </div>
      <div className={styles.form}>
        <EditInput
          value={name}
          setValue={setName}
          isRequired
          title="Ім’я та Прізвище"
        >
          <UserIcon />
        </EditInput>
        <SelectInput
          title="Роль"
          value={role}
          values={roleOptions}
          onChange={selectChange}
        />
        <EditInput value={email} setValue={setEmail} isRequired title="Пошта">
          <EmailIcon />
        </EditInput>
        <EditInput
          value={phone}
          setValue={onChangePhone}
          isRequired
          title="Номер телефону"
        >
          <PhoneIcon />
        </EditInput>

        <ModalBottomButtons
          backTitle="Видалити"
          onPressBack={props.onClose}
          applyTitle="Зберегти"
          isDeleteBackButton={true}
          onPressApply={onPressApply}
        />
      </div>
    </div>
  );
};

export default EditUserForm;

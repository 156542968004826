export function getWeekStartAndEnd(date: Date) {
  const day = date.getDay();

  const adjustedDay = day === 0 ? 7 : day;
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - (adjustedDay - 1));
  startOfWeek.setUTCHours(0, 0, 0, 0);

  const endOfWeek = new Date(date);
  endOfWeek.setDate(date.getDate() + (7 - adjustedDay));
  endOfWeek.setUTCHours(23, 59, 59, 999);

  return {
    startOfWeek: startOfWeek,
    endOfWeek: endOfWeek,
  };
}

export function goToPreviousWeek(currentDate: Date): Date {
  const previousWeek = new Date(currentDate);
  previousWeek.setDate(currentDate.getDate() - 7);
  return previousWeek;
}

export function goToNextWeek(currentDate: Date): Date {
  const nextWeek = new Date(currentDate);
  nextWeek.setDate(currentDate.getDate() + 7);
  return nextWeek;
}

import React, { ReactNode } from "react";
import styles from "./styles.module.scss";

interface Props {
  children: ReactNode;
}

const DefaultModal: React.FC<Props> = (props) => {
  return (
    <div className={styles.backdrop}>
      <div className={styles.modal}>{props.children}</div>
    </div>
  );
};

export default DefaultModal;

import { PaymentStatus } from "../types/order.type";

export const getPaymentStatus = (status: string): string => {
  switch (status) {
    case PaymentStatus.PAID:
      return "Оплачено";
    case PaymentStatus.PREPAID:
      return "Передплата";
    case PaymentStatus.UNPAID:
      return "Неоплачено";
    default:
      return "Невідомо";
  }
};

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../api/api";
import { IUser, IUserChangeDto } from "../../../api/api.interface";
import { ReactComponent as CloseIcon } from "../../../assets/icons/modal/close.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/modal/delete.svg";
import { ReactComponent as EditModalIcon } from "../../../assets/icons/modal/editusermodal.svg";
import { useUser } from "../../../providers/UserProvider";
import DefaultModal from "../DefaultModal/DefaultModal";
import ModalDeleteButtons from "../forms/buttons/ModalDeletButtons/ModalDeleteButtons";
import EditUserForm from "../forms/EditUserForm/EditUserForm";
import ViewEditUserForm from "../forms/EditUserForm/ViewEditUserForm";
import ModalTitle from "../ModalTitle/ModalTitle";
import styles from "./styles.module.scss";

interface Props {
  user: IUser;
  onClose: () => void;
  onApply: () => void;
  isInfo: boolean;
}

const EditUserModal: React.FC<Props> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(!props.isInfo);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const user = useUser();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.onClose]);

  const onPressApply = async (changedUser: IUserChangeDto) => {
    try {
      const response = await authApiService.setUser(props.user.id, changedUser);

      if (response) {
        props.onApply();
        props.onClose();
      }
    } catch (err) {
      console.error(err);
      toast.error("Сталась помилка. Спробуйте пізніше.");
    }
  };

  const onPressDelete = async () => {
    if (user.user?.id === props.user.id) {
      toast.error(
        "Ви не можете видалити себе. Це може зробити тільки інший адміністратор."
      );
      return;
    }

    try {
      const response = await authApiService.deleteUser(props.user.id);

      if (response) {
        props.onApply();
        props.onClose();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DefaultModal>
      <div className={styles.container}>
        {isDelete ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Підтвердіть видалення користувача"
              subTitle="Ви дійсно хочете видалити цього користувача? Цю дію неможливо скасувати."
            >
              <DeleteIcon />
            </ModalTitle>
            <ModalDeleteButtons
              onPressApply={onPressDelete}
              onPressBack={() => {
                setIsDelete(false);
              }}
              applyTitle="Видалити"
              backTitle="Назад"
            />
          </>
        ) : isEdit ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Редагувати користувача"
              subTitle='Редагуйте інформацію користувача. Внесіть необхідні зміни та натисніть "Зберегти" для оновлення даних.'
            >
              <EditModalIcon />
            </ModalTitle>
            <EditUserForm
              user={props.user}
              onClose={() => setIsDelete(true)}
              onPressApply={onPressApply}
            />
          </>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.upSide}>
                <img
                  src={props.user.avatarUrl}
                  alt="Avatar"
                  className={styles.avatar}
                />
                <button className={styles.upSideButton} onClick={props.onClose}>
                  <CloseIcon />
                </button>
              </div>
              <div className={styles.nameContainer}>
                <span className={styles.name}>{props.user.fullName}</span>
                <span className={styles.email}>{props.user.email}</span>
              </div>
            </div>
            <ViewEditUserForm
              user={props.user}
              onClose={props.onClose}
              onPressEdit={() => setIsEdit(true)}
            />
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default EditUserModal;

import React from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/management/edit.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/management/info.svg";
import { IClient } from "../../types/clients.type";
import styles from "./styles.module.scss";

interface Props {
  clients: IClient[];
  callBackInfo: (bool: boolean) => void;
  callBackEdit: (user: IClient) => void;
}

const ClientsTable: React.FC<Props> = (props) => {
  return (
    <table className={styles.customTable}>
      <thead>
        <tr>
          <th>Імʼя клієнта</th>
          <th>Контактна інформація</th>
          <th>Кількість оренд</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.clients &&
          props.clients.map((user, index) => (
            <tr key={user.id}>
              <td>
                <div className={styles.userInfo}>
                  <div className={styles.nameContainer}>
                    <span className={styles.name}>{user.fullName}</span>
                    <span className={styles.email}>{user.email}</span>
                  </div>
                </div>
              </td>
              <td className={styles.role}>{user.phone}</td>
              <td className={styles.role}>{user.rents.length}</td>
              <td>
                <div className={styles.actions}>
                  <button
                    className={styles.editBtn}
                    onClick={() => {
                      props.callBackInfo(false);
                      props.callBackEdit(user);
                    }}
                  >
                    <EditIcon />
                  </button>
                  <button
                    className={styles.infoBtn}
                    onClick={() => {
                      props.callBackInfo(true);
                      props.callBackEdit(user);
                    }}
                  >
                    <InfoIcon />
                  </button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ClientsTable;

import React, { useEffect, useState } from "react";
import { authApiService } from "../../../../api/api";
import { IUserChangeDto, IUserCreateDto } from "../../../../api/api.interface";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/modal/email.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/modal/phone.svg";
import { ReactComponent as UserIcon } from "../../../../assets/icons/modal/user.svg";
import { RoleType } from "../../../../types/role.type";
import {
  formatPhoneNumber,
  unformatPhoneNumber,
} from "../../../../utils/fotmatPhoneNumber";
import { getRole } from "../../../../utils/getRole";
import ChangeConfirmEmailInput from "../../../inputs/ChangeConfirmEmailInput/ChangeConfirmEmailInput";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import SelectInput, { SelectOption } from "../inputs/SelectInput/SelectInput";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onPressApply: (user: IUserChangeDto) => void;
  setIsSendEmail: () => void;
  onGetConfirmCode: (value: string) => void;
}

const roleOptions: SelectOption[] = [
  { value: RoleType.ADMIN, label: getRole(RoleType.ADMIN) },
  { value: RoleType.MASTER, label: getRole(RoleType.MASTER) },
];

const AddUserForm: React.FC<Props> = (props) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [role, setRole] = useState<SelectOption>({
    value: "none",
    label: "Оберіть роль",
  });
  const [confirmType, setConfirmType] = useState<string>("code");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const selectChange = (selectedRole: SelectOption) => {
    setRole(selectedRole);
  };

  const onChangePhone = (value: string) => {
    const formated = formatPhoneNumber(value);
    setPhone(formated);
  };

  useEffect(() => {
    if (name && email && phone && role.value !== "none") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [name, email, phone, role]);

  const onPressApply = async () => {
    setIsDisabled(true);
    try {
      const isSendEmail = confirmType === "email";
      const body: IUserCreateDto = {
        fullName: name,
        email,
        phone: unformatPhoneNumber(phone),
        role: role.value,
        avatarUrl:
          "https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png",
        isSendEmail,
      };

      const response = await authApiService.createUser(body);

      if (response) {
        if (response.user.isSendEmail) {
          // MODAL
          props.setIsSendEmail();
          return;
        } else {
          if (response.user.codeAuthorization) {
            props.onGetConfirmCode(response.user.codeAuthorization);
            return;
          }
          //   Modal confirm
        }

        console.log(response);
        props.onClose();
      }
    } catch (err) {
      console.error(err);
    }
    setIsDisabled(false);
  };

  return (
    <div className={styles.form}>
      <EditInput
        value={name}
        setValue={setName}
        isRequired
        title="Ім’я та Прізвище"
      >
        <UserIcon />
      </EditInput>
      <ChangeConfirmEmailInput
        value={confirmType}
        onChange={(value) => setConfirmType(value)}
      />
      <SelectInput
        title="Роль"
        value={role}
        values={roleOptions}
        onChange={selectChange}
      />
      <EditInput value={email} setValue={setEmail} isRequired title="Пошта">
        <EmailIcon />
      </EditInput>
      <EditInput
        value={phone}
        setValue={onChangePhone}
        isRequired
        title="Номер телефону"
      >
        <PhoneIcon />
      </EditInput>

      <ModalBottomButtons
        backTitle="Назад"
        onPressBack={props.onClose}
        applyTitle="Зберегти"
        onPressApply={onPressApply}
        isApplyDisabled={isDisabled}
      />
    </div>
  );
};

export default AddUserForm;

import React from "react";
import { IUser } from "../../../../api/api.interface";
import { ReactComponent as DateIcon } from "../../../../assets/icons/modal/date.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/modal/email.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/modal/phone.svg";
import { ReactComponent as RoleIcon } from "../../../../assets/icons/modal/role.svg";
import { formatPhoneNumber } from "../../../../utils/fotmatPhoneNumber";
import { getRole } from "../../../../utils/getRole";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";
interface Props {
  user: IUser;
  onClose: () => void;
  onPressEdit: () => void;
}

const ViewEditUserForm: React.FC<Props> = (props) => {
  return (
    <div>
      <div className={styles.form}>
        <ViewInput title="Пошта" isCopied value={props.user.email}>
          <EmailIcon />
        </ViewInput>
        <ViewInput
          title="Номер телефону"
          isCopied
          value={formatPhoneNumber(props.user.phone)}
        >
          <PhoneIcon />
        </ViewInput>
        <ViewInput title="Роль" value={getRole(props.user.role)}>
          <RoleIcon />
        </ViewInput>
        <ViewInput
          title="Дата заповнення"
          value={new Date(props.user.createdAt).toLocaleDateString()}
        >
          <DateIcon />
        </ViewInput>
      </div>
      <ModalBottomButtons
        backTitle="Назад"
        onPressBack={props.onClose}
        applyTitle="Редагувати"
        onPressApply={props.onPressEdit}
      />
    </div>
  );
};

export default ViewEditUserForm;

import React, { useEffect, useState } from "react";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/management/copycode.svg";
import { copyToClipboard } from "../../../../utils/copyToClipboard";
import GreenButton from "../../../buttons/GreenButton/GreenButton";
import styles from "./styles.module.scss";

interface Props {
  code: string;
}

const CodeSendConfirmForm: React.FC<Props> = (props) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copy = async () => {
    const res = await copyToClipboard(props.code);
    setIsCopied(res);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);
  return (
    <div>
      <div className={styles.form}>
        <div className={styles.innerForm}>
          <span className={styles.title}>Код реєстрації</span>
          <div className={styles.codeContainer}>
            {props.code.split("").map((value, index) => (
              <div className={styles.spanContainer}>
                <span className={styles.span}>{value}</span>
              </div>
            ))}
          </div>
        </div>
        <GreenButton title="Копіювати" onClick={copy} disabled={isCopied}>
          {isCopied ? <></> : <CopyIcon />}
        </GreenButton>
      </div>
    </div>
  );
};

export default CodeSendConfirmForm;

import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { authApiService } from "../../../../api/api";
import { ReactComponent as RowIcon } from "../../../../assets/icons/warehouse/row.svg";
import { IBikeRents } from "../../../../types/bikes.type";
import { IRent } from "../../../../types/rent.type";
import { formatDate } from "../../../../utils/formatDate";
import BlueButton from "../../../buttons/BlueButton/BlueButton";
import GreenLinkButton from "../../../buttons/GreenLinkButton/GreenLinkButton";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import SelectInput, { SelectOption } from "../inputs/SelectInput/SelectInput";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";

interface Props {
  order: IRent;
  onClose: () => void;
  onPressApply: () => void;
  onChangeStatus: () => void;
}

const initSelect: SelectOption = {
  value: "none",
  label: "Оберіть вільний байк",
};

const ViewOrderForm: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const isEndOrder =
    new Date(props.order.paidTo).getTime() - new Date().getTime() <= 0;
  const [bikes, setBikes] = useState<IBikeRents[]>([]);
  const [selectedBike, setSelectedBike] = useState<SelectOption>(initSelect);

  const returnBike = async () => {
    const orderEndTime = new Date();
    orderEndTime.setUTCHours(0, 0, 0, 0);
    try {
      const response = await authApiService.updateRent(props.order.id, {
        isReturned: true,
        dateTo: orderEndTime,
      });

      if (response) {
        props.onPressApply();
        props.onClose();
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const getAvailableBikes = async (dateTo: Date | null) => {
    try {
      const dateFrom = new Date();
      dateFrom.setUTCHours(0, 0, 0, 0);
      if (dateFrom && dateTo) {
        dateFrom.setUTCHours(0, 0, 0, 0);
        const date = new Date(dateTo);
        date.setUTCHours(23, 59, 59, 999);
        const response = await authApiService.getAvaliablesBikes(
          // formatDateToFetch(dateFrom),
          dateFrom.toISOString(),
          date.toISOString()
          // formatDateToFetch(dateTo)
        );

        if (response) {
          return response;
        }
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const onClickChangeBike = async () => {
    const response = await getAvailableBikes(props.order.dateTo);
    if (response) {
      if (response.length === 0) {
        toast.error("Немає вільних байків на цей час");
        return;
      }
      setBikes(response);
    }
  };

  const changeBikeToAnother = async () => {
    if (selectedBike.value === "none") {
      toast.error("Оберіть байк для заміни");
      return;
    }

    try {
      const response = await authApiService.updateRent(props.order.id, {
        isReturned: true,
      });
      if (response) {
        const changeResponse = await authApiService.updateRent(props.order.id, {
          bikeId: selectedBike.value,
          isReturned: false,
        });

        if (changeResponse) {
          props.onPressApply();
        }
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  return (
    <div>
      {bikes.length > 0 ? (
        <>
          <div className={styles.form}>
            <SelectInput
              value={selectedBike}
              values={bikes.map((item) => ({
                value: item.id,
                label: `${item.number} ${item.name}`,
              }))}
              title="Оберіть байк"
              onChange={(value) => setSelectedBike(value)}
            />
            <ViewInput
              value={`${props.order.bike.number} ${props.order.bike.name}`}
              title="Байк зараз"
            />
            <ViewInput
              value={
                selectedBike.value === "none"
                  ? "Оберіть байк"
                  : selectedBike.label
              }
              title="Замінити на"
            />
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={() => {
              setBikes([]);
              setSelectedBike(initSelect);
            }}
            applyTitle="Змінити байк"
            onPressApply={changeBikeToAnother}
          />
        </>
      ) : (
        <>
          <div className={styles.form}>
            <GreenLinkButton
              title="Клієнт"
              buttonTitle={props.order.client.fullName}
              onClick={() => {
                navigate({
                  pathname: "/clients",
                  search: `?item=${props.order.client.id}`,
                });
              }}
            >
              <RowIcon />
            </GreenLinkButton>
            <ViewInput
              value={props.order.client.phone}
              title="Контакт клієнта"
            />
            <GreenLinkButton
              title="Байк"
              buttonTitle={props.order.bike.number}
              onClick={() => {
                navigate({
                  pathname: "/bikes",
                  search: `?item=${props.order.bike.id}`,
                });
              }}
            >
              <RowIcon />
            </GreenLinkButton>
            <BlueButton
              title="Змінити байк"
              isBigButton
              onClick={onClickChangeBike}
            />
            <div className={styles.flex}>
              <ViewInput
                value={formatDate(props.order.dateFrom)}
                title="Оренда з"
              />
              <ViewInput
                value={formatDate(props.order.dateTo)}
                title="Оренда до"
              />
            </div>
            {props.order.paidTo && (
              <ViewInput
                title="Оплачено до"
                value={formatDate(props.order.paidTo)}
              />
            )}
            <ViewInput value={props.order.cost} title="Вартіть оренди (грн)" />
            {props.order.note && (
              <ViewInput value={props.order.note} title="Нотатка" isBig />
            )}
            {props.order.isReturned && (
              <ViewInput title="" value="Байк повернуто" />
            )}
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={props.onClose}
            applyTitle="Повернути байк"
            onPressApply={!props.order.isReturned ? returnBike : undefined}
          />
        </>
      )}
    </div>
  );
};

export default ViewOrderForm;

import axios from "axios";
import {
  IBike,
  IBikeCreateDto,
  IBikeRents,
  IBikeWithHistory,
} from "../types/bikes.type";
import { IClient, IClientCreateDto } from "../types/clients.type";
import { IHistory } from "../types/history.type";
import { IItems, IItemWriteOfToBike } from "../types/items.type";
import { IOrder, IOrderCreateDto, IOrderUpdateDto } from "../types/order.type";
import { IProduct, IProductCreateDto } from "../types/product.type";
import {
  IProduction,
  IProductionCreateDto,
  IProductionUpdateDto,
  IWriteOffWarehouse,
} from "../types/production.type";
import { IRent, IRentCreateDto, IRentUpdateDto } from "../types/rent.type";
import { IResource, IResourceCreateDto } from "../types/resources.type";
import { ISpendingOff } from "../types/spending.type";
import { IStatistics } from "../types/statistics.type";
import { ISupplier, ISupplierCreateDto } from "../types/suppliers.type";
import {
  IWarehouse,
  IWarehouseCreateDto,
  IWarehouseOff,
} from "../types/warehouse.type";
import { BASE_URL } from "../utils/constants/api";
import {
  IEmployee,
  IEmployeeCreateDto,
  IEmployeeUpdateDto,
  ILoginResponse,
  IUser,
  IUserChangeDto,
  IUserCreateDto,
  IUserCreateResponse,
} from "./api.interface";

export const unAuthApiService = {
  login: async (email: string, password: string): Promise<ILoginResponse> => {
    const response = await axios.post(`${BASE_URL}/auth/login`, {
      email,
      password,
    });
    return response.data;
  },
  confirmAuthentication: async (code: string): Promise<any> => {
    const response = await axios.post(
      `${BASE_URL}/auth/confirm-authorization-code`,
      { code }
    );
    return response.data;
  },
  confirmEmail: async (code: string, email: string): Promise<any> => {
    const response = await axios.post(`${BASE_URL}/auth/confirm-email`, {
      code,
      email,
    });
    return response.data;
  },
  sendNewCode: async (email: string): Promise<any> => {
    const response = await axios.post(`${BASE_URL}/auth/send-new-code`, {
      email,
    });
    return response.data;
  },
  endRegistrations: async (email: string) => {
    const response = await axios.post(`${BASE_URL}/email/end-registration`, {
      email,
    });
    return response.data;
  },
  changePassword: async (
    email: string,
    newPassword: string,
    confirmPassword: string,
    code: string
  ): Promise<any> => {
    const response = await axios.post(`${BASE_URL}/auth/change-password`, {
      email,
      newPassword,
      confirmPassword,
      code,
    });
    return response.data;
  },
  createNewPassword: async (
    newPassword: string,
    confirmPassword: string,
    code: string
  ): Promise<any> => {
    const response = await axios.post(`${BASE_URL}/auth/create-password`, {
      code,
      newPassword,
      confirmPassword,
    });
    return response.data;
  },
};

export const authApiService = {
  getMe: async (): Promise<IUser | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token || token === "undefined") {
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${BASE_URL}/users/me`, config);
    return response.data;
  },

  getAllUsers: async (): Promise<IUser[] | undefined> => {
    const token = localStorage.getItem("accessToken");
    console.log("token", token);

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${BASE_URL}/users/all?orderBy=createdAt&orderType=ASC&per_page=100`,
      config
    );

    console.log(response);

    return response.data;
  },

  setUser: async (id: string, user: IUserChangeDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.put(
      `${BASE_URL}/users/update/${id}`,
      user,
      config
    );

    return response.data;
  },

  deleteUser: async (id: string) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.delete(`${BASE_URL}/users/${id}`, config);
    return response.data;
  },

  createUser: async (
    user: IUserCreateDto
  ): Promise<IUserCreateResponse | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/auth/register/`,
      user,
      config
    );

    return response.data;
  },

  getAllEmployee: async (): Promise<IEmployee[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/employee/all?orderBy=createdAt&orderType=ASC&per_page=10000`,
      config
    );

    return response.data;
  },

  createEmployee: async (employee: IEmployeeCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/employee/create`,
      employee,
      config
    );

    return response.data;
  },

  updateEmployee: async (employee: IEmployeeUpdateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/employee/update/${employee.id}`,
      employee,
      config
    );

    return response.data;
  },

  deleteEmployee: async (id: string) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.delete(
      `${BASE_URL}/employee/delete/${id}`,
      config
    );

    return response.data;
  },

  uploadImage: async (formData: FormData) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log(formData);

    const response = await axios.post(
      `${BASE_URL}/files/upload`,
      formData,
      config
    );

    return response.data;
  },

  getAllResources: async (): Promise<IResource[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/item/all?orderBy=createdAt&orderType=ASC&per_page=10000`,
      config
    );

    return response.data;
  },
  createResource: async (
    resourse: IResourceCreateDto
  ): Promise<any | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/item/create`,
      resourse,
      config
    );

    return response.data;
  },
  deleteResource: async (id: string) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.delete(
      `${BASE_URL}/item/delete/${id}`,
      config
    );

    return response.data;
  },

  updateResource: async (id: string, resource: IResourceCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/item/update/${id}`,
      resource,
      config
    );

    return response.data;
  },

  getAllSuppliers: async (): Promise<ISupplier[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/providers/all?orderBy=createdAt&orderType=ASC&per_page=10000`,
      config
    );

    return response.data;
  },

  createSupplier: async (body: ISupplierCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/providers/create`,
      body,
      config
    );

    return response.data;
  },

  updateSupplier: async (id: string, body: ISupplierCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/providers/update/${id}`,
      body,
      config
    );

    return response.data;
  },

  deleteSupplier: async (id: string) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.delete(
      `${BASE_URL}/providers/delete/${id}`,
      config
    );

    return response.data;
  },

  getAllWarehouse: async (): Promise<IWarehouse[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/resources/all-with-relations?orderBy=createdAt&orderType=ASC&per_page=10000`,
      config
    );

    return response.data;
  },

  createWarehouse: async (body: IWarehouseCreateDto[]) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/warehouses/create/resource-warehouse`,
      body,
      config
    );

    return response.data;
  },

  updateWarehouse: async (id: string, body: IWarehouseCreateDto[]) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/warehouses/update/resource-warehouse/${id}`,
      body,
      config
    );

    return response.data;
  },

  deleteWarehouse: async (id: string) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.delete(
      `${BASE_URL}/warehouses/delete/resource-warehouse/${id}`,
      config
    );

    return response.data;
  },

  getAllClients: async (): Promise<IClient[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/clients/all?orderBy=createdAt&orderType=ASC&per_page=10000`,
      config
    );

    return response.data;
  },
  createClient: async (body: IClientCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/clients/create`,
      body,
      config
    );

    return response.data;
  },
  updateClient: async (id: string, body: IClientCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/clients/update/${id}`,
      body,
      config
    );

    return response.data;
  },
  deleteClient: async (id: string) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.delete(
      `${BASE_URL}/clients/delete/${id}`,
      config
    );

    return response.data;
  },
  getAllProducts: async (): Promise<IProduct[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/wares/all?orderBy=createdAt&orderType=ASC&per_page=10000`,
      config
    );

    return response.data;
  },
  createProduct: async (body: IProductCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(`${BASE_URL}/wares/create`, body, config);

    return response.data;
  },
  updateProduct: async (id: string, body: IProductCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/wares/update/${id}`,
      body,
      config
    );

    return response.data;
  },
  deleteProduct: async (id: string) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.delete(
      `${BASE_URL}/wares/delete/${id}`,
      config
    );

    return response.data;
  },
  getAllOrders: async (): Promise<IOrder[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/orders/all?orderBy=createdAt&orderType=DESC&per_page=10000`,
      config
    );

    return response.data.data;
  },
  createOrder: async (body: IOrderCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/orders/create`,
      body,
      config
    );

    return response.data;
  },
  updateOrder: async (id: string, body: IOrderCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/orders/update/${id}`,
      body,
      config
    );

    return response.data;
  },

  updateStatusOrder: async (id: string, body: IOrderUpdateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/orders/update/${id}`,
      body,
      config
    );

    return response.data;
  },
  deleteOrder: async (id: string) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.delete(
      `${BASE_URL}/orders/delete/${id}`,
      config
    );

    return response.data;
  },

  getAllProduction: async (): Promise<IProduction[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/production/all?orderBy=createdAt&orderType=DESC&per_page=10000`,
      config
    );

    return response.data;
  },
  createProduction: async (body: IProductionCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/production/create`,
      body,
      config
    );

    return response.data;
  },
  updateProduction: async (id: string, body: IProductionUpdateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/production/update/${id}`,
      body,
      config
    );

    return response.data;
  },
  // updateProduction: async (id: string, body: IProductionCreateDto) => {

  // }
  writeOffResource: async (id: string, body: IWriteOffWarehouse) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/production/update/${id}/with-warehouse-management`,
      body,
      config
    );

    return response.data;
  },
  getAllHistory: async (): Promise<IHistory[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/history/all?orderBy=createdAt&orderType=DESC&per_page=10000`,
      config
    );

    return response.data.data;
  },
  writeOffResourceFromWarehouse: async (body: IWarehouseOff) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/production/write-off-resources`,
      body,
      config
    );

    return response.data;
  },
  uploadCdnImage: async (formData: FormData) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log(formData);

    const response = await axios.post(
      `${BASE_URL}/files-cdn/upload`,
      formData,
      config
    );

    return response.data;
  },
  getAllBikes: async (): Promise<IBike[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/bike/all?orderBy=createdAt&orderType=DESC&per_page=10000`,
      config
    );

    return response.data;
  },
  createBike: async (body: IBikeCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(`${BASE_URL}/bike/create`, body, config);

    return response.data;
  },
  updateBike: async (id: string, body: IBikeCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/bike/update/${id}`,
      body,
      config
    );

    return response.data;
  },
  getOneBike: async (id: string): Promise<IBikeWithHistory | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(`${BASE_URL}/bike/one/${id}`, config);

    return response.data;
  },
  getAllItems: async (): Promise<IItems[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/item/all?orderBy=createdAt&orderType=DESC&per_page=10000`,
      config
    );

    return response.data;
  },
  writeOfItemsToBike: async (body: IItemWriteOfToBike) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/spending/write-off-to-bike`,
      body,
      config
    );

    return response.data;
  },
  spendingOff: async (body: ISpendingOff) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/spending/write-off`,
      body,
      config
    );

    return response.data;
  },
  getRentsByDate: async (
    dateFrom: string,
    dateTo: string
  ): Promise<IRent[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/rent/all-with-dates?dateFrom=${dateFrom}&dateTo=${dateTo}&per_page=1000000`,
      config
    );

    return response.data;
  },
  getAvaliablesBikes: async (
    dateFrom: string,
    dateTo: string
  ): Promise<IBikeRents[] | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/bike/all-available?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      config
    );

    return response.data;
  },
  createRent: async (body: IRentCreateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(`${BASE_URL}/rent/create`, body, config);

    return response.data;
  },
  updateRent: async (id: string, body: IRentUpdateDto) => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.put(
      `${BASE_URL}/rent/update/${id}`,
      body,
      config
    );

    return response.data;
  },
  getStatistics: async (
    dateFrom: string,
    dateTo: string
  ): Promise<IStatistics | undefined> => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${BASE_URL}/statistics/all?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      config
    );

    return response.data;
  },
};

import React from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { unAuthApiService } from "../../../../api/api";
import AuthTitle from "../../../AuthTitle/AuthTitle";
import TextWithLink from "../../../links/TextWithLink/TextWithLink";
import CodeForm from "../CodeForm/CodeForm";
import styles from "./styles.module.scss";

const RegisterCodeForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsCode = queryParams.get("code");

  const confirmCode = async (code: string) => {
    try {
      return await unAuthApiService.confirmAuthentication(code);
    } catch (err) {
      console.error(err);
    }
  };

  const sendCode = async (code: string) => {
    console.log(code);
    try {
      const response = await confirmCode(code);
      if (!response) {
        console.log("Error");
        return;
      }

      navigate("/signupPassword", { state: { code } });
    } catch (err: any) {
      toast.error(err.message);
    }
  };
  return (
    <div className={styles.container}>
      <AuthTitle
        title="Доступ до реєстрації"
        subTitle="Введіть код підтвердження, який був наданий адміністратором, щоб продовжити процес реєстрації."
      />
      <div className={styles.inputsContainer}>
        <CodeForm onPressApply={sendCode} code={paramsCode} />
        <TextWithLink
          text="Вже маєте обліковий запис? "
          linkText="Вхід"
          to="/login"
        />
      </div>
    </div>
  );
};

export default RegisterCodeForm;

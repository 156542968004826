import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import { useAuth } from "../../../providers/AuthProvider";
import { InputEnum } from "../../../types/input.type";
import { validateEmail } from "../../../utils/validation/email";
import { validatePassword } from "../../../utils/validation/password";
import AuthTitle from "../../AuthTitle/AuthTitle";
import BlueButton from "../../buttons/BlueButton/BlueButton";
import LoginInput from "../../inputs/LoginInput/LoginInput";
import TextWithLink from "../../links/TextWithLink/TextWithLink";
import styles from "./styles.module.scss";

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigation = useNavigate();
  const auth = useAuth();
  const [errorPasswordMessage, setErrorPasswordMessage] = useState<string>("");
  const [errorEmailMessage, setErrorEmailMessage] = useState<string>("");

  useEffect(() => {
    setErrorPasswordMessage("");
    setErrorEmailMessage("");
  }, [email, password]);

  const onChangeEmail = (value: string) => {
    setEmail(value);
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  const onPressLogin = async () => {
    const validEmail = await validateEmail(email);
    const validPassword = await validatePassword(password);
    console.log(validEmail, validPassword);

    if (!validEmail.success || !validPassword.success) {
      validEmail.error && setErrorEmailMessage(validEmail.error);
      validPassword.error && setErrorPasswordMessage(validPassword.error);

      return;
    }

    const response = await auth.login(email, password);

    if (response) {
      navigation("/");
      return;
    }
    toast.error("Сталась помилка. Перевірьте email або пароль");
  };

  return (
    <div className={styles.container}>
      <AuthTitle
        title="Вітаємо в Electrovel"
        subTitle="Будь ласка, увійдіть до системи, використовуючи свої облікові дані."
      />
      <div className={styles.inputsContainer}>
        <LoginInput
          name="Email"
          isRequired
          type={InputEnum.EMAIL}
          value={email}
          onChange={onChangeEmail}
          errorMessage={errorEmailMessage}
        />
        <div className={styles.passwordContainer}>
          <LoginInput
            name="Password"
            isRequired
            type={InputEnum.PASSWORD}
            value={password}
            onChange={onChangePassword}
            errorMessage={errorPasswordMessage}
          />
          <Link to="/forgotPassword" className={styles.link}>
            Забули пароль?
          </Link>
        </div>
      </div>
      <BlueButton
        title="Увійти"
        onClick={onPressLogin}
        disabled={email.length < 3 || password.length < 6}
      />
      <TextWithLink
        to="/signupCode"
        text="Не маєте облікового запису? "
        linkText="Зареєструватися"
      />
    </div>
  );
};

export default LoginForm;

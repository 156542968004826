import React, { useState } from "react";
import { ReactComponent as InvalidIcon } from "../../../assets/icons/auth/invalid.svg";
import { ReactComponent as HideIcon } from "../../../assets/icons/auth/notsee.svg";
import { ReactComponent as ShowIcon } from "../../../assets/icons/auth/see.svg";
import { InputEnum } from "../../../types/input.type";
import styles from "./styles.module.scss";

interface Props {
  name: string;
  isRequired: boolean;
  type: InputEnum;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
}

const LoginInput: React.FC<Props> = (props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <label className={styles.title} htmlFor={props.name}>
        {props.name}{" "}
        {props.isRequired && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        <input
          type={
            props.type === InputEnum.PASSWORD
              ? isVisible
                ? "text"
                : InputEnum.PASSWORD
              : props.type
          }
          name={props.name}
          id={props.name}
          className={`${styles.input} ${
            props.type === InputEnum.EMAIL && styles.email
          } ${props.type === InputEnum.PASSWORD && styles.password} ${
            props.errorMessage && styles.error
          }`}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />

        {props.errorMessage && (
          <div className={styles.errorContainer}>
            <InvalidIcon />
            <span className={styles.errorMessage}>{props.errorMessage}</span>
          </div>
        )}

        {props.type === InputEnum.PASSWORD && (
          <button
            onClick={() => setIsVisible(!isVisible)}
            className={styles.button}
          >
            {!isVisible ? <ShowIcon /> : <HideIcon />}
          </button>
        )}
      </div>
    </div>
  );
};

export default LoginInput;

import React from "react";
import { ReactComponent as NextIcon } from "../../../../../assets/icons/modal/next.svg";
import styles from "./styles.module.scss";

interface Props {
  onPressApply: () => void;
  applyTitle: string;
  isNextIcon?: boolean;
  backTitle: string;
  onPressBack: () => void;
}

const ModalDeleteButtons: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <button onClick={props.onPressBack} className={styles.backButton}>
        {props.backTitle}
      </button>
      <button
        onClick={props.onPressApply}
        className={`${styles.applyButton} ${styles.deleteButton}`}
      >
        <span>{props.applyTitle}</span> {props.isNextIcon && <NextIcon />}
      </button>
    </div>
  );
};

export default ModalDeleteButtons;

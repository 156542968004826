import { PaymentType } from "../types/order.type";

export const getPaymentType = (status: string): string => {
  switch (status) {
    case PaymentType.CASH:
      return "Готівковий";
    case PaymentType.NON_CASH:
      return "Безготівковий";
    default:
      return "Невідомо";
  }
};

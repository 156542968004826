import { IClient } from "./clients.type";

export interface IOrder {
  id: string;
  name: string;
  paymentStatus: string;
  orderStatus: string;
  productCost: number;
  marginCost: number;
  packagingCost: number;
  totalCost: number;
  paymentType: string;
  createdAt: Date;
  updatedAt: Date;
  client: IClient;
  wares: Array<{
    id: string;
    count: number;
    ware: {
      id: string;
      name: string;
      description: string;
      minimalEmployeeCount: number;
      avatarUrl: string;
      createdAt: string;
      updatedAt: string;
    };
  }>;
  production: {
    id: string;
    productionStatus: string;
    createdAt: Date;
    updatedAt: Date;
  } | null;
}

export interface IOrderCreateDto {
  name: string;
  clientId: string;
  paymentStatus: string;
  orderStatus?: string;
  wares: Array<{
    wareId: string;
    count: number;
  }>;
  productCost: number;
  marginCost: number;
  packagingCost: number;
  totalCost: number;
  paymentType: string;
}

export interface IOrderUpdateDto {
  orderStatus?: string;
  paymentStatus?: string;
}

export enum PaymentStatus {
  UNPAID = "unpaid",
  PAID = "paid",
  PREPAID = "prepaid",
}

export enum PaymentType {
  CASH = "cash",
  NON_CASH = "non cash",
}

export enum ProgressStatus {
  NEW_ORDER = "new order",
  IN_WORK = "in work",
  SHIPPED = "shipped",
}

export enum OrderStatus {
  NEW_ORDER = "new_order",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  CANCELED = "canceled",
  SHIPPED = "shipped",
}

import React, { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/filter/search.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/warehouse/check.svg";
import { ReactComponent as UncheckIcon } from "../../../assets/icons/warehouse/uncheck.svg";

import toast from "react-hot-toast";
import { ISelectsInput } from "../../../types/selects.type";
import { filterSelectItems } from "../../../utils/filterUsers";
import EditInput from "../../modals/forms/inputs/EditInput/EditInput";
import styles from "./styles.module.scss";

interface Props {
  items: ISelectsInput[];
  title: string;
  isMultiSelect?: boolean;
  value?: ISelectsInput | null;
  values?: ISelectsInput[];
  onClickInactive: (value: ISelectsInput) => void;
  onClickActive: (value: ISelectsInput) => void;
  onChangeCount: (id: string, count: number) => void;
  search: string;
  setSearch: (value: string) => void;
}

const SelectChangeProdListInput: React.FC<Props> = (props) => {
  const [itemsList, setItemsList] = useState<ISelectsInput[]>(props.items);

  const onChangeSearch = () => {
    if (!props.search) {
      setItemsList(props.items);
      return;
    }
    setItemsList(filterSelectItems(props.items, props.search));
  };

  const onChangeCount = (id: string, value: string, count: number) => {
    if (isNaN(Number(value))) {
      toast.error("Очікується числове значення");
      return;
    }
    const numberValue = Number(value);
    if (numberValue > count) {
      toast.error("Не можна списати більше запчастин ніж є на складі");
      return;
    }
    props.onChangeCount(id, Number(value));
  };

  useEffect(() => {
    onChangeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search]);

  return (
    <div className={styles.container}>
      <EditInput
        value={props.search}
        isRequired={false}
        setValue={(value) => props.setSearch(value)}
      >
        <SearchIcon />
      </EditInput>
      <div className={styles.buttonsContainer}>
        {itemsList.map((item) => {
          if (
            props.isMultiSelect
              ? props.values?.some((list) => list.id === item.id)
              : item.id === props.value?.id
          ) {
            return (
              <div className={styles.all} key={item.id}>
                <button
                  key={item.id}
                  className={`${styles.button} ${styles.active}`}
                  type="button"
                  onClick={() => props.onClickActive(item)}
                >
                  <div className={styles.subContainer}>
                    <CheckIcon />
                    <span className={styles.titleContainer}>
                      <span>{item.name}</span>
                      <span className={styles.subTitleContainer}>
                        {item.subName && (
                          <span className={styles.subTitle}>
                            {item.subName}
                          </span>
                        )}
                        {item.secondSubName && (
                          <span className={styles.subTitle}>
                            / {item.secondSubName}
                          </span>
                        )}
                      </span>
                    </span>
                  </div>

                  {item.count && <span>{item.count}</span>}
                </button>

                <input
                  type="text"
                  className={styles.input}
                  value={item.selectCount}
                  height={"100%"}
                  onChange={(e) => {
                    if (item.count) {
                      onChangeCount(item.id, e.target.value, item.count);
                    }
                  }}
                />
              </div>
            );
          } else {
            return (
              <div className={styles.all} key={item.id}>
                <button
                  key={item.id}
                  className={`${styles.button} ${styles.inactive}`}
                  type="button"
                  onClick={() => props.onClickInactive(item)}
                >
                  <div className={styles.subContainer}>
                    <UncheckIcon />
                    <span className={styles.titleContainer}>
                      <span className={styles.name}>{item.name}</span>
                      <span className={styles.subTitleContainer}>
                        {item.subName && (
                          <span className={styles.subTitle}>
                            {item.subName}{" "}
                          </span>
                        )}
                        {item.secondSubName && (
                          <span className={styles.subTitle}>
                            / {item.secondSubName} грн.
                          </span>
                        )}
                      </span>
                    </span>
                  </div>
                  {item.count && <span>{item.count}</span>}
                </button>
                <div className={`${styles.input} ${styles.disabled}`} />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SelectChangeProdListInput;

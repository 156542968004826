import React, { useEffect, useState } from "react";
import { authApiService } from "../../../api/api";
import { ReactComponent as CloseIcon } from "../../../assets/icons/modal/close.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/modal/delete.svg";
import { ReactComponent as EditModalIcon } from "../../../assets/icons/modal/resource.svg";
import { IBike } from "../../../types/bikes.type";
import { IResource } from "../../../types/resources.type";
import DefaultModal from "../DefaultModal/DefaultModal";
import ModalDeleteButtons from "../forms/buttons/ModalDeletButtons/ModalDeleteButtons";
import EditResourceForm from "../forms/EditResourceForm/EditResourceForm";
import ViewResourceForm from "../forms/EditResourceForm/ViewResourceForm";
import ModalTitle from "../ModalTitle/ModalTitle";
import styles from "./styles.module.scss";

interface Props {
  resource: IResource;
  onClose: () => void;
  onApply: () => void;
  isInfo: boolean;
  bikes: IBike[];
}

const EditResourceModal: React.FC<Props> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(!props.isInfo);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  const onPressApply = async () => {
    props.onApply();
    props.onClose();
  };

  const onPressDelete = async () => {
    try {
      const response = await authApiService.deleteResource(props.resource.id);

      if (response.message === "Deleted failed.") {
        return;
      }
      if (response) {
        props.onApply();
        props.onClose();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DefaultModal>
      <div className={styles.container}>
        {isDelete ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Підтвердіть видалення запчастини"
              subTitle="Ви дійсно хочете видалити цю запчастину? Цю дію неможливо скасувати."
            >
              <DeleteIcon />
            </ModalTitle>
            <ModalDeleteButtons
              onPressApply={onPressDelete}
              onPressBack={() => {
                setIsDelete(false);
              }}
              applyTitle="Видалити"
              backTitle="Назад"
            />
          </>
        ) : isEdit ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Редагування запчастини"
              subTitle='Редагуйте інформацію про запчастину. Внесіть необхідні зміни та натисніть "Зберегти" для оновлення даних.'
            >
              <EditModalIcon />
            </ModalTitle>
            <EditResourceForm
              onClose={props.onClose}
              onApply={onPressApply}
              resource={props.resource}
            />
          </>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.upSide}>
                <div />
                <button className={styles.upSideButton} onClick={props.onClose}>
                  <CloseIcon />
                </button>
              </div>
              <div className={styles.nameContainer}>
                <span className={styles.name}>{props.resource.name}</span>
                <span className={styles.email}>
                  {/* {props.resource.provider.name} */}
                </span>
              </div>
            </div>
            <ViewResourceForm
              resource={props.resource}
              onClose={props.onClose}
              onPressEdit={() => setIsEdit(true)}
              bikes={props.bikes}
              onApply={props.onApply}
            />
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default EditResourceModal;

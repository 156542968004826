import React, { useState } from "react";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/modal/filter.svg";
import { RoleType } from "../../../../types/role.type";
import { getRole } from "../../../../utils/getRole";
import DefaultModal from "../../DefaultModal/DefaultModal";
import ModalBottomButtons from "../../forms/buttons/ModakBottomButtons/ModalBottomButtons";
import SelectInput, {
  SelectOption,
} from "../../forms/inputs/SelectInput/SelectInput";
import ModalTitle from "../../ModalTitle/ModalTitle";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: (role: SelectOption, status: SelectOption) => void;
  defaultRole: SelectOption;
  defaultStatus: SelectOption;
}

export const roleOptions: SelectOption[] = [
  { label: "Будь який", value: "none" },
  { value: RoleType.ADMIN, label: getRole(RoleType.ADMIN) },
  { value: RoleType.MASTER, label: getRole(RoleType.MASTER) },
];

export const registeredOptions: SelectOption[] = [
  { label: "Будь який", value: "none" },
  { label: "Незареєстрований", value: "not_registered" },
  { label: "Зареєстрований", value: "registered" },
];

const UserFilterModal: React.FC<Props> = (props) => {
  const [role, setRole] = useState<SelectOption>(
    props.defaultRole || roleOptions[0]
  );
  const [registered, setRegistered] = useState<SelectOption>(
    props.defaultStatus || registeredOptions[0]
  );
  return (
    <DefaultModal>
      <div className={styles.container}>
        <ModalTitle
          title="Фільтр"
          subTitle='Виберіть параметри фільтра для відображення потрібних даних. Після налаштування натисніть "Застосувати" для отримання результату.'
          onClose={props.onClose}
        >
          <FilterIcon />
        </ModalTitle>
        <div className={styles.form}>
          <SelectInput
            title="Роль"
            value={role}
            values={roleOptions}
            onChange={(value) => setRole(value)}
          />
          <SelectInput
            title="Статус"
            value={registered}
            values={registeredOptions}
            onChange={(value) => setRegistered(value)}
          />
        </div>
        <ModalBottomButtons
          onPressApply={() => {
            props.onApply(role, registered);
            props.onClose();
          }}
          onPressBack={props.onClose}
          backTitle="Назад"
          applyTitle="Застосувати"
        />
      </div>
    </DefaultModal>
  );
};

export default UserFilterModal;

import React from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const SmallWhiteButton: React.FC<Props> = (props) => {
  return (
    <button
      className={styles.smallButton}
      onClick={props.onClick}
      disabled={props.disabled}
      type="button"
    >
      {props.title}
    </button>
  );
};

export default SmallWhiteButton;

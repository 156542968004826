import React from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/management/edit.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/management/info.svg";
import { IBike } from "../../types/bikes.type";
import { formatDate } from "../../utils/formatDate";
import styles from "./styles.module.scss";

interface Props {
  bikes: IBike[];
  callBackInfo: (bool: boolean) => void;
  callBackEdit: (user: IBike) => void;
}

const BikesTable: React.FC<Props> = (props) => {
  return (
    <table className={styles.customTable}>
      <thead>
        <tr>
          <th>Номер</th>
          <th>Вартість</th>
          <th>Дата створення</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.bikes &&
          props.bikes.map((user, index) => (
            <tr key={user.id}>
              <td>
                <div className={styles.userInfo}>
                  <div className={styles.nameContainer}>
                    <span className={styles.name}>{user.number}</span>
                    <span className={styles.email}>{user.name}</span>
                  </div>
                </div>
              </td>
              <td className={styles.role}>{user.price}</td>
              <td className={styles.role}>{formatDate(user.createdAt)}</td>
              <td>
                <div className={styles.actions}>
                  <button
                    className={styles.editBtn}
                    onClick={() => {
                      props.callBackInfo(false);
                      props.callBackEdit(user);
                    }}
                  >
                    <EditIcon />
                  </button>
                  <button
                    className={styles.infoBtn}
                    onClick={() => {
                      props.callBackInfo(true);
                      props.callBackEdit(user);
                    }}
                  >
                    <InfoIcon />
                  </button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default BikesTable;

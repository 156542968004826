import { AuthPagesEnum } from "../types/authPages.type";

export const getPageName = (path: string): string => {
  switch (path) {
    case AuthPagesEnum.DEAFAULT:
      return "Вітаємо в Electrovel!";
    case AuthPagesEnum.STATISTICS:
      return "Статистика";
    case AuthPagesEnum.ORDERS:
      return "Оренда";
    case AuthPagesEnum.CLIENTS:
      return "Клієнти";
    case AuthPagesEnum.PRODUCTION:
      return "Виробництво";
    case AuthPagesEnum.PRODUCT:
      return "Вироби";
    case AuthPagesEnum.WAREHOUSE:
      return "Склад";
    case AuthPagesEnum.RESOURCES:
      return "Запчастини";
    case AuthPagesEnum.SUPPLEIERS:
      return "Постачальники";
    case AuthPagesEnum.EMPLOYEES:
      return "Працівники";
    case AuthPagesEnum.MANAGEMENT:
      return "Керування користувачами";
    case AuthPagesEnum.HISTORY:
      return "Історія списання";
    case AuthPagesEnum.BIKES:
      return "Велосипеди";
    default:
      return "Вітаємо в Electrovel!";
  }
};

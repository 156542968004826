import React, { LegacyRef, useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { ReactComponent as ContactIcon } from "../../../../assets/icons/supplier/contact.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/supplier/email.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/supplier/phone.svg";
import { IClientCreateDto } from "../../../../types/clients.type";
import { BASE_URL } from "../../../../utils/constants/api";
import {
  formatPhoneNumber,
  unformatPhoneNumber,
} from "../../../../utils/fotmatPhoneNumber";
import SelectFileButton from "../../../buttons/SelectFileButton/SelectFileButton";
import SmallWhiteButton from "../../../buttons/WhiteButton/SmallWhiteButton";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: () => void;
}

const AddClientForm: React.FC<Props> = (props) => {
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const fileInputRef: LegacyRef<HTMLInputElement> | undefined =
    React.createRef();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevState) => [...prevState, file]);
    }
  };

  const onChangePhone = (value: string) => {
    const formated = formatPhoneNumber(value);
    setPhone(formated);
  };

  const onApply = async () => {
    if (!fullName) {
      toast.error('Поле "Імʼя" обовʼязкове');
      return;
    }
    const files = [];
    for (const file of selectedFiles) {
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const response = await authApiService.uploadImage(formData);
          files.push(`${BASE_URL}/${response.path}`);
        } catch (err: any) {
          console.error(err);
          toast.error(err.message);
        }
      }
    }
    const body: IClientCreateDto = {
      fullName,
      phone: unformatPhoneNumber(phone),
      email,
      note,
      files,
    };
    try {
      const response = await authApiService.createClient(body);

      if (response) {
        props.onApply();
        props.onClose();
      }
    } catch (err) {
      console.error(err);
      toast.error("Сталась помилка. Спробуйте пізніше");
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form}>
        <EditInput
          value={fullName}
          setValue={(value) => setFullName(value)}
          title="Ім’я та Прізвище"
          isRequired={true}
        >
          <ContactIcon />
        </EditInput>
        <EditInput
          value={email}
          setValue={(value) => setEmail(value)}
          title="Telegram"
          isRequired={false}
        >
          <EmailIcon />
        </EditInput>

        <EditInput
          value={phone}
          setValue={onChangePhone}
          title="Номер телефону"
          isRequired={false}
        >
          <PhoneIcon />
        </EditInput>
        <EditInput
          value={note}
          setValue={(value) => setNote(value)}
          title="Примітка"
          isRequired={false}
          isBig={true}
        />
        {selectedFiles.map((file, index) => (
          <SelectFileButton
            key={index}
            title={file.name}
            onClick={() => {
              setSelectedFiles((prevState) => {
                const newState = [...prevState];
                newState.splice(index, 1);
                return newState;
              });
            }}
          />
        ))}
        <input
          id="file-upload"
          type="file"
          accept="file/*"
          onChange={handleFileChange}
          ref={fileInputRef}
          className={styles.input}
        />
        <SmallWhiteButton
          onClick={handleButtonClick}
          title="Завантажити файл"
        />
      </form>
      <ModalBottomButtons
        onPressBack={props.onClose}
        onPressApply={onApply}
        backTitle="Назад"
        applyTitle="Додати клієнта"
      />
    </div>
  );
};

export default AddClientForm;

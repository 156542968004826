import React, { useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { useUser } from "../../../../providers/UserProvider";
import {
  IResource,
  IResourceCreateDto,
} from "../../../../types/resources.type";
import { RoleType } from "../../../../types/role.type";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: () => void;
  resource: IResource;
}

const EditResourcesForm: React.FC<Props> = (props) => {
  const user = useUser();
  const isMaster = user.user?.role === RoleType.MASTER;
  const [name, setName] = useState<string>(props.resource.name);
  const [description, setDescription] = useState<string>(
    props.resource.description ? props.resource.description : ""
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [price, setPrice] = useState<string>(props.resource.price.toString());
  const [quantity, setQuantity] = useState<string>(
    props.resource.quantity.toString()
  );

  const onChangeCount = (value: string, callback: any) => {
    if (isNaN(Number(value))) {
      toast.error("Поле очікує числове значення");
      return;
    }
    callback(value);
  };

  const onClickApply = async () => {
    if (isMaster) {
      if (Number(props.resource.quantity) > Number(quantity)) {
        toast.error(
          "Майстер не має права зменшити кількість ресурсів. Зверніться до адміністратора."
        );
        return;
      }
    }

    setIsDisabled(true);
    if (!name || !quantity || !price) {
      toast.error("Заповність всі обовʼязкові поля");
      return;
    }

    try {
      const body: IResourceCreateDto = {
        name,
        quantity: Number(quantity),
        price: Number(price),
        description,
      };

      const response = await authApiService.updateResource(
        props.resource.id,
        body
      );

      if (response) {
        props.onApply();
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }

    setIsDisabled(false);
  };

  return (
    <div className={styles.container}>
      <form className={styles.form}>
        {isMaster ? (
          <ViewInput title="Назва" value={name} />
        ) : (
          <EditInput
            title="Назва"
            value={name}
            setValue={(value) => setName(value)}
            isRequired
          />
        )}
        {isMaster ? (
          <ViewInput title="Вартість" value={price} />
        ) : (
          <EditInput
            title="Вартість"
            value={price}
            setValue={(value) => onChangeCount(value, setPrice)}
            isRequired
          />
        )}
        <EditInput
          title="Кількість"
          value={quantity}
          setValue={(value) => onChangeCount(value, setQuantity)}
          isRequired
        />

        <EditInput
          title="Опис"
          value={description}
          setValue={(value) => setDescription(value)}
          isBig={true}
          isRequired={false}
        />
      </form>

      <ModalBottomButtons
        onPressApply={onClickApply}
        applyTitle="Змінити запчастину"
        onPressBack={props.onClose}
        backTitle="Назад"
        isApplyDisabled={isDisabled}
      />
    </div>
  );
};

export default EditResourcesForm;

import React, { useEffect, useState } from "react";
import { authApiService } from "../../api/api";
import FilterBlock from "../../components/FilterBlock/FilterBlock";
import AddOrderModal from "../../components/modals/AddOrderModal/AddOrderModal";
import EditOrderModal from "../../components/modals/EditOrderModal/EditOrderModal";
import { SelectOption } from "../../components/modals/forms/inputs/SelectInput/SelectInput";
import Pagination from "../../components/pagination/Pagination/Pagination";
import OrdersTable from "../../components/Tables/OrdersTable";
import TableTitle from "../../components/TableTitle/TableTitle";
import WeekSelector from "../../components/WeekSelector/WeekSelector";
import { useUser } from "../../providers/UserProvider";
import { IClient } from "../../types/clients.type";
import { IProduct } from "../../types/product.type";
import { IRent } from "../../types/rent.type";
import { usersPerPage } from "../../utils/constants/page";
import { filterRent } from "../../utils/filterUsers";
import {
  getWeekStartAndEnd,
  goToNextWeek,
  goToPreviousWeek,
} from "../../utils/getWeekStartAndEnd";
import styles from "./styles.module.scss";

const OrdersPage: React.FC = () => {
  const { user } = useUser();
  const [orders, setOrders] = useState<IRent[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<IRent[]>([]);
  const [editSelectedOrder, setEditSelectedOrder] = useState<IRent | null>(
    null
  );
  const [visibleOrders, setVisibleOrders] = useState<IRent[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isAddOrderModal, setIsAddOrderModal] = useState<boolean>(false);
  const [isInfoModal, setInfoModal] = useState<boolean>(false);
  const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [selectClient, setSelectClient] = useState<SelectOption>({
    value: "none",
    label: "Будь-який",
  });
  const [selectPayment, setSelectPayment] = useState<SelectOption>({
    value: "none",
    label: "Будь-який",
  });
  const [selectType, setSelectType] = useState<SelectOption>({
    value: "none",
    label: "Будь-який",
  });
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const onPressPreviousWeek = () => {
    const result = goToPreviousWeek(currentDate);
    setCurrentDate(result);
  };

  const onPressNextWeek = () => {
    const result = goToNextWeek(currentDate);
    setCurrentDate(result);
  };

  const getOrders = async (date: Date) => {
    try {
      const dates = getWeekStartAndEnd(date);
      const results = await authApiService.getRentsByDate(
        // formatDateToFetch(dates.startOfWeek),
        dates.startOfWeek.toISOString(),
        dates.endOfWeek.toISOString()

        // formatDateToFetch(dates.endOfWeek)
      );
      console.log(results);
      return results;
    } catch (err) {
      console.error(err);
    }
  };

  const getClients = async () => {
    try {
      const results = await authApiService.getAllClients();
      return results;
    } catch (err) {
      console.error(err);
    }
  };

  const changeVisibiltyOrders = (orders: IRent[]) => {
    const indexOfLastUser = activePage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    if (orders) {
      const changedOrders = orders.slice(indexOfFirstUser, indexOfLastUser);

      setVisibleOrders(changedOrders);
    }
  };

  const getOptionsFilter = (
    client: SelectOption,
    payment: SelectOption,
    type: SelectOption
  ) => {
    let filteredorders = [...orders];

    return filteredorders;
  };

  const onFilterChange = (
    client: SelectOption,
    payment: SelectOption,
    type: SelectOption
  ) => {
    const data = getOptionsFilter(client, payment, type);
    const filteredUsers = filterRent(data, searchValue);
    setFilteredOrders(filteredUsers);
  };

  const getFiltredOrders = (orders: IRent[]) => {
    const data = getOptionsFilter(selectClient, selectPayment, selectType);
    const filtered = filterRent(data, searchValue);
    setFilteredOrders(filtered);
  };

  const onClickApplyModal = async () => {
    const resp = await getOrders(currentDate);
    if (resp) {
      setOrders(resp);
      getFiltredOrders(resp);
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredOrders.length / usersPerPage));
  }, [filteredOrders]);

  useEffect(() => {
    getFiltredOrders(orders);
    // setActivePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, orders]);

  useEffect(() => {
    (async () => {
      const data = await getOrders(currentDate);
      if (data) {
        setOrders(data);
      }

      const cli = await getClients();
      if (cli) {
        setClients(cli);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getOrders(currentDate);
      if (res) {
        setOrders(res);
      }
    })();
  }, [currentDate]);

  useEffect(() => {
    changeVisibiltyOrders(filteredOrders);
    // setTotalPages(Math.ceil(users.length / usersPerPage));
    if (activePage > totalPages) {
      if (activePage === 1) {
        setActivePage(1);
      } else {
        setActivePage(totalPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, orders, totalPages, filteredOrders]);
  return (
    <main className={styles.container}>
      <div className={styles.header}>
        <TableTitle title="Всього" count={orders?.length || 0} />
        <div>
          <FilterBlock
            searchValue={searchValue}
            onSearchChange={(value) => setSearchValue(value)}
            onPressAdd={() => setIsAddOrderModal(true)}
            // onPressModal={() => setIsFilterModal(true)}
            addButtonTitle="Додати"
          />
        </div>
      </div>
      <WeekSelector
        onClickLeft={onPressPreviousWeek}
        onClickRight={onPressNextWeek}
        date={currentDate}
      />
      <OrdersTable
        orders={visibleOrders}
        callBackInfo={setInfoModal}
        callBackEdit={setEditSelectedOrder}
      />

      <Pagination
        key={totalPages}
        count={totalPages}
        activePage={activePage}
        onClick={(page) => setActivePage(page)}
        onClickNext={() => setActivePage((prevState) => (prevState += 1))}
        onClickPrev={() => setActivePage((prevState) => (prevState -= 1))}
      />
      {isAddOrderModal && (
        <AddOrderModal
          clients={clients}
          onClose={() => setIsAddOrderModal(false)}
          onApply={onClickApplyModal}
        />
      )}
      {editSelectedOrder && (
        <EditOrderModal
          order={editSelectedOrder}
          isInfo={isInfoModal}
          onClose={() => setEditSelectedOrder(null)}
          onApply={onClickApplyModal}
        />
      )}
    </main>
  );
};

export default OrdersPage;

import React, { useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { IBikeCreateDto } from "../../../../types/bikes.type";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: () => void;
}

const AddBikeForm: React.FC<Props> = (props) => {
  const [number, setNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [gps, setGps] = useState<string>("");
  const [frame, setFrame] = useState<string>("");

  const onChangePrice = (value: string) => {
    if (isNaN(Number(value))) {
      toast.error("Поле очікує числове значення");
      return;
    }

    setPrice(value);
  };

  const onApplyCreateBike = async () => {
    try {
      const body: IBikeCreateDto = {
        number,
        name,
        price: Number(price),
        note,
        gps,
        frame,
      };

      const response = await authApiService.createBike(body);

      if (response) {
        toast.success("Байк створенно");
        props.onApply();
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.form}>
        <EditInput
          title="Обліковий номер"
          value={number}
          setValue={(value) => setNumber(value)}
          isRequired
        />
        <EditInput
          title="Назва"
          value={name}
          setValue={(value) => setName(value)}
          isRequired={false}
        />
        <EditInput
          title="GPS, SIM card"
          value={gps}
          setValue={(value) => setGps(value)}
          isRequired
        />
        <EditInput
          title="№ рами"
          value={frame}
          setValue={(value) => setFrame(value)}
          isRequired
        />
        <EditInput
          title="Вартість оренди"
          value={price}
          setValue={(value) => onChangePrice(value)}
          isRequired
        />
        <EditInput
          title="Нотатка"
          value={note}
          setValue={(value) => setNote(value)}
          isRequired={false}
          isBig
        />
      </form>
      <ModalBottomButtons
        onPressBack={props.onClose}
        onPressApply={onApplyCreateBike}
        backTitle="Назад"
        applyTitle="Додати байк"
      />
    </div>
  );
};

export default AddBikeForm;

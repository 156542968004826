import React from "react";
import Select, { SingleValue } from "react-select";
import styles from "./styles.module.scss";

export interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  title?: string;
  value: SelectOption;
  values: SelectOption[];
  onChange: (value: SelectOption) => void;
}

const SelectInput: React.FC<Props> = (props) => {
  const handleChange = (selectedOption: SingleValue<SelectOption>) => {
    if (selectedOption) {
      props.onChange(selectedOption);
    }
  };

  return (
    <div className={styles.container}>
      {props.title && (
        <label htmlFor={props.title} className={styles.label}>
          {props.title} <span className={styles.require}>*</span>
        </label>
      )}
      <Select
        id={props.title}
        value={props.value}
        onChange={handleChange}
        options={props.values}
        styles={{
          indicatorSeparator: (base) => ({
            ...base,
            width: "0px",
          }),
          dropdownIndicator: (base) => ({ ...base, padding: 0 }),
          option: (base, state) => ({
            ...base,
            display: "flex",
            justifyContent: "flex-start",
            backgroundColor: state.isFocused ? "#3359e8" : "transparent",
            color: state.isFocused ? "#fff" : "black",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: 1.5,
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "8px",
          }),
          control: (base) => ({
            ...base,
            color: "#101828",
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            padding: "14px 14px 14px 16px",
            borderRadius: "8px",
            borderWidth: "1px",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: "0.01em",
            textAlign: "left",
            outline: "none",
            borderBlockWidth: 1,
            boxShadow: "none",
            ":active": {
              borderColor: "#3359e8",
              outlineWidth: 0,
            },
          }),
          input: (base) => ({
            ...base,
            margin: 0,
            padding: 0,
            marginLeft: "8px",
          }),
          singleValue: (base) => ({
            ...base,
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "8px",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: "0.01em",
            color: "#171818",
          }),
          valueContainer: (base) => ({
            ...base,
            margin: 0,
            padding: 0,
          }),
          menu: (base) => ({
            ...base,
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: "130px",
            overflow: "auto",
          }),
        }}
      />
    </div>
  );
};

export default SelectInput;

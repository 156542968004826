import * as Yup from "yup";
import { validationMessage } from "../translation";
import { ValidationResponse } from "./email";

const passwordValidationSchema = Yup.object({
  password: Yup.string()
    .min(6, validationMessage.minLengthPassword)
    .required(validationMessage.requiredField),
});

export async function validatePassword(
  password: string
): Promise<ValidationResponse> {
  try {
    await passwordValidationSchema.validate({ password });
    return { success: true };
  } catch (e: any) {
    return { success: false, error: e.message };
  }
}

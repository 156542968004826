import React from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  count: number;
}

const TableTitle: React.FC<Props> = (props) => {
  return (
    <span className={styles.title}>
      {props.title} <span className={styles.count}>{props.count}</span>
    </span>
  );
};

export default TableTitle;

import React, { ReactNode, useEffect, useState } from "react";
import { ReactComponent as CopyIcon } from "../../../../../assets/icons/modal/copy.svg";
import { ReactComponent as CheckIcon } from "../../../../../assets/icons/modal/copyCheck.svg";
import { copyToClipboard } from "../../../../../utils/copyToClipboard";
import styles from "./styles.module.scss";

interface Props {
  title?: string;
  value: string;
  children?: ReactNode;
  isCopied?: boolean;
  isBig?: boolean;
}

const ViewInput: React.FC<Props> = (props) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copy = async () => {
    const res = await copyToClipboard(props.value);
    setIsCopied(res);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  return (
    <div className={styles.container}>
      {props.title && <span className={styles.title}>{props.title}</span>}
      {props.isBig ? (
        <textarea
          style={{ resize: "none" }}
          readOnly
          value={props.value}
          className={`${styles.containerInnerArea} ${styles.value}`}
        />
      ) : (
        <div className={styles.containerInner}>
          <div className={styles.valueContainer}>
            {props.children}
            <span className={styles.value}>{props.value}</span>
          </div>
          {props.isCopied && (
            <button
              className={styles.button}
              disabled={isCopied}
              onClick={(e) => {
                e.preventDefault();
                copy();
              }}
            >
              {isCopied ? <CheckIcon /> : <CopyIcon />}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewInput;

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../api/api";
import { ReactComponent as ClientIcon } from "../../../assets/icons/modal/client.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/modal/delete.svg";
import { IBike } from "../../../types/bikes.type";
import { IItems } from "../../../types/items.type";
import DefaultModal from "../DefaultModal/DefaultModal";
import ModalDeleteButtons from "../forms/buttons/ModalDeletButtons/ModalDeleteButtons";
import EditBikeForm from "../forms/EditBikeForm/EditBikeForm";
import ViewBikeForm from "../forms/EditBikeForm/ViewBikeForm";
import ModalTitle from "../ModalTitle/ModalTitle";
import styles from "./styles.module.scss";

interface Props {
  bike: IBike;
  onClose: () => void;
  onApply: () => void;
  isInfo: boolean;
  items: IItems[];
}

const EditBikeModal: React.FC<Props> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(!props.isInfo);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  const onPressApply = async () => {
    props.onApply();
    props.onClose();
  };

  const onPressDelete = async () => {
    try {
      const response = await authApiService.deleteClient(props.bike.id);
      console.log(response);

      if (response) {
        props.onApply();
        props.onClose();
      }
    } catch (err: any) {
      console.error(err.message);
      toast.error(err.message);
    }
  };

  return (
    <DefaultModal>
      <div className={styles.container}>
        {isDelete ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Підтвердіть видалення байка"
              subTitle="Ви дійсно хочете видалити цього клієнта? Цю дію неможливо скасувати."
            >
              <DeleteIcon />
            </ModalTitle>
            <ModalDeleteButtons
              onPressApply={onPressDelete}
              onPressBack={() => {
                setIsDelete(false);
              }}
              applyTitle="Видалити"
              backTitle="Назад"
            />
          </>
        ) : isEdit ? (
          <>
            <ModalTitle
              onClose={props.onClose}
              title="Редагувати байк"
              subTitle='Редагуйте інформацію про байк. Внесіть необхідні зміни та натисніть "Зберегти".'
            >
              <ClientIcon />
            </ModalTitle>
            <EditBikeForm
              onApply={onPressApply}
              onClose={props.onClose}
              bike={props.bike}
            />
          </>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.nameContainer}>
                <span className={styles.name}>{props.bike.number}</span>
                <span className={styles.email}>{props.bike.name}</span>
              </div>
            </div>
            <ViewBikeForm
              onClose={props.onClose}
              onPressEdit={() => setIsEdit(true)}
              bike={props.bike}
              items={props.items}
              onWriteOf={props.onApply}
            />
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default EditBikeModal;

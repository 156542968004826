import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { unAuthApiService } from "../../../api/api";
import { useAuth } from "../../../providers/AuthProvider";
import { InputEnum } from "../../../types/input.type";
import { validationMessage } from "../../../utils/translation";
import { validatePassword } from "../../../utils/validation/password";
import AuthTitle from "../../AuthTitle/AuthTitle";
import BlueButton from "../../buttons/BlueButton/BlueButton";
import WhiteButton from "../../buttons/WhiteButton/WhiteBotton";
import LoginInput from "../../inputs/LoginInput/LoginInput";
import styles from "./styles.module.scss";

const ResetPasswordForm: React.FC = () => {
  const location = useLocation();
  const [password, setPassword] = useState<string>("");
  const [errorPasswordMessage, setErrorPasswordMessage] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorConfirmPasswordMessage, setErrorConfirmPasswordMessage] =
    useState<string>("");
  const navigation = useNavigate();
  const auth = useAuth();

  const { code, email } = location.state;

  if (!code) {
    navigation("/login");
  }

  useEffect(() => {
    setErrorPasswordMessage("");
    setErrorConfirmPasswordMessage("");
  }, [confirmPassword, password]);

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  const onChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value);
  };

  const changePassword = async () => {
    try {
      return await unAuthApiService.changePassword(
        email,
        password,
        confirmPassword,
        code
      );
    } catch (err: any) {
      toast.error(err.message);
      console.error(err);
    }
  };

  const onPressApply = async () => {
    const validConfirmPassword = await validatePassword(confirmPassword);
    const validPassword = await validatePassword(password);

    if (!validPassword || !validConfirmPassword) {
      validPassword.error && setErrorPasswordMessage(validPassword.error);
      validConfirmPassword.error &&
        setErrorConfirmPasswordMessage(validConfirmPassword.error);
      return;
    }
    if (password !== confirmPassword) {
      setErrorPasswordMessage(validationMessage.passwordsMustBeSame);
      setErrorConfirmPasswordMessage(validationMessage.passwordsMustBeSame);
      return;
    }

    //   AXIOS

    const response = await changePassword();
    if (!response) {
      return;
    }

    navigation("/login");
  };

  return (
    <div className={styles.container}>
      <AuthTitle
        title="Оновити пароль"
        subTitle="Оновіть свій пароль. Введіть новий пароль і підтвердіть його, щоб відновити доступ до вашого облікового запису."
      />
      <div className={styles.inputsContainer}>
        <LoginInput
          name="Password"
          isRequired
          type={InputEnum.PASSWORD}
          value={password}
          onChange={onChangePassword}
          errorMessage={errorPasswordMessage}
        />
        <div className={styles.passwordContainer}>
          <LoginInput
            name="Confirm Password"
            isRequired
            type={InputEnum.PASSWORD}
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            errorMessage={errorConfirmPasswordMessage}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <BlueButton
            title="Оновити пароль"
            onClick={onPressApply}
            disabled={confirmPassword.length < 6 || password.length < 6}
          />
          <WhiteButton
            title="Назад"
            onClick={() => {
              navigation("/login");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authApiService } from "../../api/api";
import FilterBlock from "../../components/FilterBlock/FilterBlock";
import AddBikeModal from "../../components/modals/AddBikeModal/AddBikeModal";
import EditBikeModal from "../../components/modals/EditBikeModal/EditBikeModal";
import Pagination from "../../components/pagination/Pagination/Pagination";
import BikesTable from "../../components/Tables/BikesTable";
import TableTitle from "../../components/TableTitle/TableTitle";
import { IBike } from "../../types/bikes.type";
import { IItems } from "../../types/items.type";
import { usersPerPage } from "../../utils/constants/page";
import { filterBikes } from "../../utils/filterUsers";
import styles from "./styles.module.scss";

const BikesPage: React.FC = () => {
  const [bikes, setBikes] = useState<IBike[]>([]);
  const [filteredBikes, setFilteredBikes] = useState<IBike[]>([]);
  const [editSelectedBikes, setEditSelectedBike] = useState<IBike | null>(null);
  const [visibleBikes, setVisibleBike] = useState<IBike[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isAddBikeModal, setIsAddBikeModal] = useState<boolean>(false);
  const [isInfoModal, setInfoModal] = useState<boolean>(false);
  const [items, setItems] = useState<IItems[]>([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get("item");
  const navigate = useNavigate();

  const removeQueryParam = (key: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
      searchParams.delete(key);
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  };

  const getBikes = async () => {
    try {
      const results = await authApiService.getAllBikes();
      return results;
    } catch (err) {
      console.error(err);
    }
  };

  const getItems = async () => {
    try {
      const results = await authApiService.getAllItems();
      return results;
    } catch (err) {
      console.error(err);
    }
  };

  const changeVisibiltyBikes = (clients: IBike[]) => {
    const indexOfLastUser = activePage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    if (clients) {
      const changedClients = clients.slice(indexOfFirstUser, indexOfLastUser);
      setVisibleBike(changedClients);
    }
  };

  const getOptionsFilter = () => {
    let filteredBikes = [...bikes];
    // setRole(role);

    // if (role.value !== EmployeeType.NONE) {
    //   filteredUsers = filteredUsers.filter((user) => user.role === role.value);
    // }

    return filteredBikes;
  };

  const getFiltredBikes = (clients: IBike[]) => {
    const data = getOptionsFilter();
    const filtered = filterBikes(data, searchValue);
    setFilteredBikes(filtered);
  };

  const onClickApplyModal = async () => {
    const resp = await getBikes();
    if (resp) {
      setBikes(resp);
      getFiltredBikes(resp);
    }
    const itms = await getItems();
    if (itms) {
      setItems(itms);
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredBikes.length / usersPerPage));
  }, [filteredBikes]);

  useEffect(() => {
    getFiltredBikes(bikes);
    // setActivePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, bikes]);

  useEffect(() => {
    (async () => {
      const data = await getBikes();
      if (data) {
        setBikes(data);
      }

      const it = await getItems();
      if (it) {
        setItems(it);
      }

      if (clientId) {
        const item = data?.find((res) => res.id === clientId);
        if (item) {
          setInfoModal(true);
          setEditSelectedBike(item);
          removeQueryParam("item");
        }
      }
    })();
  }, []);

  useEffect(() => {
    changeVisibiltyBikes(filteredBikes);
    // setTotalPages(Math.ceil(users.length / usersPerPage));
    if (activePage > totalPages) {
      if (activePage === 1) {
        setActivePage(1);
      } else {
        setActivePage(totalPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, bikes, totalPages, filteredBikes]);
  return (
    <main className={styles.container}>
      <div className={styles.header}>
        <TableTitle title="Всього" count={bikes?.length || 0} />
        <div>
          <FilterBlock
            searchValue={searchValue}
            onSearchChange={(value) => setSearchValue(value)}
            onPressAdd={() => setIsAddBikeModal(true)}
            addButtonTitle="Додати"
          />
        </div>
      </div>

      <BikesTable
        bikes={visibleBikes}
        callBackInfo={setInfoModal}
        callBackEdit={setEditSelectedBike}
      />
      <Pagination
        key={totalPages}
        count={totalPages}
        activePage={activePage}
        onClick={(page) => setActivePage(page)}
        onClickNext={() => setActivePage((prevState) => (prevState += 1))}
        onClickPrev={() => setActivePage((prevState) => (prevState -= 1))}
      />
      {isAddBikeModal && (
        <AddBikeModal
          onApply={onClickApplyModal}
          onClose={() => setIsAddBikeModal(false)}
        />
      )}

      {editSelectedBikes && (
        <EditBikeModal
          bike={editSelectedBikes}
          onClose={() => setEditSelectedBike(null)}
          onApply={onClickApplyModal}
          isInfo={isInfoModal}
          items={items}
        />
      )}
    </main>
  );
};

export default BikesPage;

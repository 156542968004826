import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authApiService } from "../../api/api";
import { IUser } from "../../api/api.interface";
import FilterBlock from "../../components/FilterBlock/FilterBlock";
import AddUserModal from "../../components/modals/AddUserModal/AddUserModal";
import EditUserModal from "../../components/modals/EditUserModal/EditUserModal";
import UserFilterModal, {
  registeredOptions,
  roleOptions,
} from "../../components/modals/filters/UserFilterModal/UserFilterModal";
import { SelectOption } from "../../components/modals/forms/inputs/SelectInput/SelectInput";
import Pagination from "../../components/pagination/Pagination/Pagination";
import ManagementTable from "../../components/Tables/ManagementTable";
import TableTitle from "../../components/TableTitle/TableTitle";
import { useUser } from "../../providers/UserProvider";
import { RoleType } from "../../types/role.type";
import { usersPerPage } from "../../utils/constants/page";
import { filterUsers } from "../../utils/filterUsers";
import styles from "./styles.module.scss";

const ManagementPage: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [users, setUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [editSelectedUser, setEditSelectedUser] = useState<IUser | null>(null);
  const [visibleUsers, setVisibleUsers] = useState<IUser[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isAddUserModal, setIsAddUserModal] = useState<boolean>(false);
  const [isInfoModal, setInfoModal] = useState<boolean>(false);
  const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
  const [role, setRole] = useState<SelectOption>(roleOptions[0]);
  const [registered, setRegistered] = useState<SelectOption>(
    registeredOptions[0]
  );

  useEffect(() => {
    if (user?.role !== RoleType.ADMIN) {
      navigate("/");
    }
  }, [user?.role, navigate]);

  const getUsers = async () => {
    try {
      const response = await authApiService.getAllUsers();
      if (response) {
        return response;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const changeVisibiltyUser = (users: IUser[]) => {
    const indexOfLastUser = activePage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    if (users) {
      const changedUsers = users.slice(indexOfFirstUser, indexOfLastUser);
      setVisibleUsers(changedUsers);
    }
  };

  const getOptionsFilter = (role: SelectOption, registered: SelectOption) => {
    let filteredUsers = [...users];
    setRole(role);
    setRegistered(registered);

    if (role.value !== "none") {
      filteredUsers = filteredUsers.filter((user) => user.role === role.value);
    }

    if (registered.value !== "none") {
      filteredUsers = filteredUsers.filter(
        (user) => user.status === registered.value
      );
    }

    return filteredUsers;
  };

  const onFilterChange = (role: SelectOption, registered: SelectOption) => {
    const data = getOptionsFilter(role, registered);
    const filteredUsers = filterUsers(data, searchValue);
    setFilteredUsers(filteredUsers);
  };

  const getFiltredUsers = (users: IUser[]) => {
    const data = getOptionsFilter(role, registered);
    const filteredUsers = filterUsers(data, searchValue);
    setFilteredUsers(filteredUsers);
    // setTotalPages(Math.ceil(filteredUsers.length / usersPerPage));
  };

  const onClickApplyModal = async () => {
    const resp = await getUsers();
    if (resp) {
      setUsers(resp);
      getFiltredUsers(resp);
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredUsers.length / usersPerPage));
  }, [filteredUsers]);

  useEffect(() => {
    getFiltredUsers(users);
    // setActivePage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, users]);

  useEffect(() => {
    (async () => {
      const data = await getUsers();
      if (data) {
        await setUsers(data);
      }
    })();
  }, []);

  useEffect(() => {
    changeVisibiltyUser(filteredUsers);
    // setTotalPages(Math.ceil(users.length / usersPerPage));
    if (activePage > totalPages) {
      if (activePage === 1) {
        setActivePage(1);
      } else {
        setActivePage(totalPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, users, totalPages, filteredUsers]);

  return (
    <main className={styles.container}>
      <div className={styles.header}>
        <TableTitle title="Всього" count={users?.length || 0} />
        <div>
          <FilterBlock
            searchValue={searchValue}
            onPressModal={() => setIsFilterModal(true)}
            onSearchChange={(value) => setSearchValue(value)}
            onPressAdd={() => setIsAddUserModal(true)}
            addButtonTitle="Додати"
          />
        </div>
      </div>
      <ManagementTable
        users={visibleUsers}
        callBackEdit={setEditSelectedUser}
        callBackInfo={setInfoModal}
      />
      <Pagination
        key={totalPages}
        count={totalPages}
        activePage={activePage}
        onClick={(page) => setActivePage(page)}
        onClickNext={() => setActivePage((prevState) => (prevState += 1))}
        onClickPrev={() => setActivePage((prevState) => (prevState -= 1))}
      />
      {editSelectedUser && (
        <EditUserModal
          user={editSelectedUser}
          onClose={() => setEditSelectedUser(null)}
          onApply={onClickApplyModal}
          isInfo={isInfoModal}
        />
      )}
      {isAddUserModal && (
        <AddUserModal
          onClose={() => setIsAddUserModal(false)}
          onApply={onClickApplyModal}
        />
      )}
      {isFilterModal && (
        <UserFilterModal
          onClose={() => setIsFilterModal(false)}
          onApply={onFilterChange}
          defaultRole={role}
          defaultStatus={registered}
        />
      )}
    </main>
  );
};

export default ManagementPage;

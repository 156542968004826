import React from "react";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import styles from "./styles.module.scss";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const ChangeConfirmEmailInput: React.FC<Props> = (props) => {
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <div className={styles.titleContainer}>
      <span className={styles.title}>
        Виберіть дію <span className={styles.required}>*</span>
      </span>
      <form className={styles.container}>
        <CustomRadioButton
          value="code"
          name="changeConfirm"
          onChange={handleOptionChange}
          label="Надати код реєстрації"
          description="Згенеруйте код реєстрації та поділіться ним з новим користувачем для завершення реєстрації."
          isChecked={props.value === "code"}
        />
        <CustomRadioButton
          value="email"
          name="changeConfirm"
          onChange={handleOptionChange}
          label="Відправити посилання з кодом реєстрації на пошту"
          description="Введіть електронну адресу користувача, і відправте йому посилання з кодом реєстрації."
          isChecked={props.value === "email"}
        />
      </form>
    </div>
  );
};

export default ChangeConfirmEmailInput;

import { WarehouseStatus } from "../types/warehouse.type";

export const getStatusWarehouse = (type: string) => {
  switch (type) {
    case WarehouseStatus.IN_STOCK:
      return "В наявності";
    case WarehouseStatus.IS_EXPECTED:
      return "Очікується";
    case WarehouseStatus.SHORTAGE:
      return "Брак";
    default:
      return "Не визначено";
  }
};

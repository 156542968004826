import React, { useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { IResourceCreateDto } from "../../../../types/resources.type";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: () => void;
}

const AddResourcesForm: React.FC<Props> = (props) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [price, setPrice] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");

  const onChangeCount = (value: string, callback: any) => {
    if (isNaN(Number(value))) {
      toast.error("Поле очікує числове значення");
      return;
    }
    callback(value);
  };

  const onClickApply = async () => {
    setIsDisabled(true);
    if (!name || !quantity || !price) {
      toast.error("Заповність всі обовʼязкові поля");
      return;
    }

    try {
      const body: IResourceCreateDto = {
        name,
        quantity: Number(quantity),
        price: Number(price),
        description,
      };

      const response = await authApiService.createResource(body);

      if (response) {
        props.onApply();
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }

    setIsDisabled(false);
  };

  return (
    <div className={styles.container}>
      <form className={styles.form}>
        <EditInput
          title="Назва"
          value={name}
          setValue={(value) => setName(value)}
          isRequired
        />
        <div className={styles.editContainer}>
          <EditInput
            title="Вартість"
            value={price}
            setValue={(value) => onChangeCount(value, setPrice)}
            isRequired
          />
          <EditInput
            title="Кількість"
            value={quantity}
            setValue={(value) => onChangeCount(value, setQuantity)}
            isRequired
          />
        </div>
        <EditInput
          title="Опис"
          value={description}
          setValue={(value) => setDescription(value)}
          isBig={true}
          isRequired={false}
        />
      </form>

      <ModalBottomButtons
        onPressApply={onClickApply}
        applyTitle="Додати запчастину"
        onPressBack={() => {}}
        backTitle="Назад"
        isApplyDisabled={isDisabled}
      />
    </div>
  );
};

export default AddResourcesForm;

import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";

export const formatPhoneNumber = (value: string) => {
  const phoneNumber = new AsYouType("UA").input(value);
  return phoneNumber;
};

export const unformatPhoneNumber = (formattedPhoneNumber: string) => {
  const phoneNumber = parsePhoneNumberFromString(formattedPhoneNumber, "UA");
  if (phoneNumber) {
    return phoneNumber.number;
  }
  return formattedPhoneNumber;
};

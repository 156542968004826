import React from "react";
import { ReactComponent as RowIcon } from "../../assets/icons/modal/row.svg";
import { ReactComponent as CheckStep } from "../../assets/icons/modal/stepcheck.svg";
import styles from "./styles.module.scss";

interface Props {
  step: number;
  fitstStepTitle: string;
  secondStepTitle: string;
  thirdStepTitle?: string;
  isBigModal?: boolean;
}

const StepsHeader: React.FC<Props> = (props) => {
  return (
    <>
      <div
        className={`${styles.stepContainer} ${
          props.isBigModal ? styles.bigModal : ""
        }`}
      >
        <div className={styles.stepSubContainer}>
          <div
            className={`${styles.step} ${props.step >= 1 ? styles.active : ""}`}
          >
            {props.step !== 1 ? (
              <CheckStep />
            ) : (
              <span
                className={`${styles.stepText} ${
                  props.step === 1 ? styles.active : ""
                }`}
              >
                01
              </span>
            )}
          </div>

          <span className={styles.text}>{props.fitstStepTitle}</span>
        </div>
        <RowIcon />
        <div className={styles.stepSubContainer}>
          <div
            className={`${styles.step} ${props.step >= 2 ? styles.active : ""}`}
          >
            {props.step > 2 ? (
              <CheckStep />
            ) : (
              <span
                className={`${styles.stepText} ${
                  props.step === 2 ? styles.active : ""
                }`}
              >
                02
              </span>
            )}
          </div>
          <span className={styles.text}>{props.secondStepTitle}</span>
        </div>
        {props.thirdStepTitle && (
          <>
            <RowIcon />
            <div className={styles.stepSubContainer}>
              <div
                className={`${styles.step} ${
                  props.step > 3 ? styles.active : ""
                }`}
              >
                <span
                  className={`${styles.stepText} ${
                    props.step > 3 ? styles.active : ""
                  }`}
                >
                  03
                </span>
              </div>
              <span className={styles.text}>{props.thirdStepTitle}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StepsHeader;

import React from "react";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/supplier/phone.svg";

import { IClient } from "../../../../types/clients.type";
import { formatPhoneNumber } from "../../../../utils/fotmatPhoneNumber";
import FilesView from "../../../FileView/FilesView";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";
interface Props {
  client: IClient;
  onClose: () => void;
  onPressEdit: () => void;
}

const ViewClientForm: React.FC<Props> = (props) => {
  return (
    <div>
      {(props.client.email || props.client.phone || props.client.note) && (
        <div className={styles.form}>
          {props.client.email && (
            <ViewInput
              title="Telegram"
              isCopied
              value={props.client.email}
            ></ViewInput>
          )}
          {props.client.phone && (
            <ViewInput
              title="Номер телефону"
              value={formatPhoneNumber(props.client.phone)}
              isCopied
            >
              <PhoneIcon />
            </ViewInput>
          )}

          {props.client.note && (
            <ViewInput
              title="Примітка"
              value={props.client.note}
              isBig={true}
            />
          )}

          {props.client.files && props.client.files.length > 0 && (
            <div className={styles.filesContainer}>
              <span className={styles.title}>Файли клієнта</span>
              {props.client.files.map((file) => (
                <FilesView url={file} key={file} />
              ))}
            </div>
          )}
        </div>
      )}
      <ModalBottomButtons
        backTitle="Назад"
        onPressBack={props.onClose}
        applyTitle="Редагувати"
        onPressApply={props.onPressEdit}
      />
    </div>
  );
};

export default ViewClientForm;

import React, { useState } from "react";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/modal/filter.svg";
import DateRangeInputs from "../../../inputs/DateRangeInputs/DateRangeInputs";
import DefaultModal from "../../DefaultModal/DefaultModal";
import ModalBottomButtons from "../../forms/buttons/ModakBottomButtons/ModalBottomButtons";
import ModalTitle from "../../ModalTitle/ModalTitle";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: (startDate: Date | null, endDate: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
}

const HistoryFilterModal: React.FC<Props> = (props) => {
  const [startDate, setStartDate] = useState<Date | null>(props.startDate);
  const [endDate, setEndDate] = useState<Date | null>(props.endDate);

  return (
    <DefaultModal>
      <div className={styles.container}>
        <ModalTitle
          title="Фільтр"
          subTitle='Виберіть параметри фільтра для відображення потрібних даних. Після налаштування натисніть "Застосувати" для отримання результату.'
          onClose={props.onClose}
        >
          <FilterIcon />
        </ModalTitle>
        <div className={styles.form}>
          <DateRangeInputs
            start={startDate}
            end={endDate}
            onChangeStart={(value) => setStartDate(value)}
            onChangeEnd={(value) => setEndDate(value)}
          />
        </div>
        <ModalBottomButtons
          onPressApply={() => {
            props.onApply(startDate, endDate);
            props.onClose();
          }}
          onPressBack={props.onClose}
          backTitle="Назад"
          applyTitle="Застосувати"
        />
      </div>
    </DefaultModal>
  );
};

export default HistoryFilterModal;

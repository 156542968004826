import React from "react";
import styles from "./styles.module.scss";

interface Props {
  page: number;
  onClick: (page: number) => void;
  isActive: boolean;
}

const PaginationButton: React.FC<Props> = (props) => {
  return (
    <button
      className={`${styles.button} ${props.isActive ? styles.isActive : ""}`}
      onClick={() => props.onClick(props.page)}
    >
      {props.page}
    </button>
  );
};

export default PaginationButton;

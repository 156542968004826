import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authApiService } from "../../api/api";
import FilterBlock from "../../components/FilterBlock/FilterBlock";
import AddResourceModal from "../../components/modals/AddResourceModal/AddResourceModal";
import EditResourceModal from "../../components/modals/EditResourceModal/EditResourceModal";
import Pagination from "../../components/pagination/Pagination/Pagination";
import ResourceTable from "../../components/Tables/ResourcesTable";
import TableTitle from "../../components/TableTitle/TableTitle";
import { useUser } from "../../providers/UserProvider";
import { IBike } from "../../types/bikes.type";
import { IResource } from "../../types/resources.type";
import { RoleType } from "../../types/role.type";
import { usersPerPage } from "../../utils/constants/page";
import { filterResources } from "../../utils/filterUsers";
import styles from "./styles.module.scss";

const ResourcesPage: React.FC = () => {
  const [resourses, setResources] = useState<IResource[]>([]);
  const [isAddResourceModal, setIsAddResourceModal] = useState<boolean>(false);
  const [editSelectedResource, setEditSelectedResource] =
    useState<IResource | null>(null);
  const [visibleResources, setVisibleResources] = useState<IResource[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isInfoModal, setInfoModal] = useState<boolean>(false);
  const [filteredResources, setFilteredResources] = useState<IResource[]>([]);
  const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
  const [bikes, setBikes] = useState<IBike[]>([]);
  const user = useUser();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resourceId = searchParams.get("item");
  const navigate = useNavigate();

  const removeQueryParam = (key: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
      searchParams.delete(key);
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  };

  const getResources = async () => {
    try {
      const results = await authApiService.getAllResources();
      console.log(results);
      return results;
    } catch (err) {
      console.error(err);
    }
  };
  const getBikes = async () => {
    try {
      const results = await authApiService.getAllBikes();
      console.log(results);
      return results;
    } catch (err) {
      console.error(err);
    }
  };

  const changeVisibiltyResources = (resources: IResource[]) => {
    const indexOfLastUser = activePage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    if (resources) {
      const changedResources = resources.slice(
        indexOfFirstUser,
        indexOfLastUser
      );
      setVisibleResources(changedResources);
    }
  };

  const getOptionsFilter = () => {
    let filteredResources = [...resourses];

    return filteredResources;
  };

  const onFilterChange = () => {
    const data = getOptionsFilter();
    const filtered = filterResources(data, searchValue);
    setFilteredResources(filtered);
  };

  const getFiltredResource = (suppliers: IResource[]) => {
    const data = getOptionsFilter();
    const filtered = filterResources(data, searchValue);
    setFilteredResources(filtered);
  };

  const onClickApplyModal = async () => {
    const resp = await getResources();
    if (resp) {
      setResources(resp);
      setFilteredResources(resp);
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredResources.length / usersPerPage));
  }, [filteredResources]);

  useEffect(() => {
    getFiltredResource(resourses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, resourses]);

  useEffect(() => {
    changeVisibiltyResources(filteredResources);
    if (activePage > totalPages) {
      if (activePage === 1) {
        setActivePage(1);
      } else {
        setActivePage(totalPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, resourses, totalPages, filteredResources]);

  useEffect(() => {
    (async () => {
      const data = await getResources();
      if (data) {
        setResources(data);
      }

      const bk = await getBikes();
      if (bk) {
        setBikes(bk);
      }

      if (resourceId) {
        const item = data?.find((res) => res.id === resourceId);
        if (item) {
          setInfoModal(true);
          setEditSelectedResource(item);
          removeQueryParam("item");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={styles.container}>
      <div className={styles.header}>
        <TableTitle title="Всього" count={resourses?.length || 0} />
        <div>
          <FilterBlock
            searchValue={searchValue}
            onSearchChange={(value) => setSearchValue(value)}
            onPressAdd={
              user.user?.role === RoleType.ADMIN
                ? () => setIsAddResourceModal(true)
                : undefined
            }
            addButtonTitle="Додати"
          />
        </div>
      </div>
      <ResourceTable
        resources={visibleResources}
        callBackEdit={setEditSelectedResource}
        callBackInfo={setInfoModal}
      />
      <Pagination
        key={totalPages}
        count={totalPages}
        activePage={activePage}
        onClick={(page) => setActivePage(page)}
        onClickNext={() => setActivePage((prevState) => (prevState += 1))}
        onClickPrev={() => setActivePage((prevState) => (prevState -= 1))}
      />
      {isAddResourceModal && (
        <AddResourceModal
          onClose={() => setIsAddResourceModal(false)}
          onApply={onClickApplyModal}
        />
      )}
      {editSelectedResource && (
        <EditResourceModal
          isInfo={isInfoModal}
          resource={editSelectedResource}
          onClose={() => {
            setEditSelectedResource(null);
          }}
          onApply={onClickApplyModal}
          bikes={bikes}
        />
      )}
    </main>
  );
};

export default ResourcesPage;

import React, { useEffect } from "react";
import { IHistory } from "../../../types/history.type";
import DefaultModal from "../DefaultModal/DefaultModal";
import ViewHistoryForm from "../forms/ViewHistoryForm/ViewHistoryForm";
import ModalTitle from "../ModalTitle/ModalTitle";
import styles from "./styles.module.scss";

interface Props {
  history: IHistory;
  onClose: () => void;
}

const ViewHistoryModal: React.FC<Props> = (props) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  return (
    <DefaultModal>
      <div className={styles.container}>
        <ModalTitle
          onClose={props.onClose}
          title={props.history.bikeNumber}
        ></ModalTitle>
        <ViewHistoryForm history={props.history} onClose={props.onClose} />
      </div>
    </DefaultModal>
  );
};

export default ViewHistoryModal;

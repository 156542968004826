import React from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  onClick: () => void;
}

const SelectFileButton: React.FC<Props> = (props) => {
  return (
    <div className={styles.fileEditContainer}>
      <span className={styles.fileName}>{props.title}</span>
      <button
        type="button"
        className={styles.deleteButton}
        onClick={props.onClick}
      >
        x
      </button>
    </div>
  );
};

export default SelectFileButton;

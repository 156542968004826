import React from "react";
import PaginationButton from "../PaginationButton/PaginationButton";
import styles from "./styles.module.scss";

interface Props {
  count: number;
  onClick: (page: number) => void;
  activePage: number;
  onClickNext: () => void;
  onClickPrev: () => void;
}

const Pagination: React.FC<Props> = (props) => {
  return (
    <>
      {props.count !== 0 && (
        <div className={styles.container}>
          <button
            onClick={props.onClickPrev}
            className={styles.button}
            disabled={props.activePage === 1}
          >
            Минула
          </button>
          <div className={styles.countContainer}>
            {Array.from({ length: props.count }, (_, index) => (
              <PaginationButton
                key={index + 1}
                page={index + 1}
                isActive={index + 1 === props.activePage}
                onClick={props.onClick}
              />
            ))}
          </div>
          <button
            onClick={props.onClickNext}
            className={styles.button}
            disabled={props.activePage === props.count}
          >
            Наступна
          </button>
        </div>
      )}
    </>
  );
};

export default Pagination;

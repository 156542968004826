export interface IResource {
  id: string;
  name: string;
  description: string;
  price: string;
  quantity: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IResourceCreateDto {
  name: string;
  description?: string;
  price: number;
  quantity: number;
}

export enum ResourceType {
  NONE = "none",
  CONSUMERS = "consumers",
  ACCESSOIRES = "accessories",
}

import React from "react";
import styles from "./styles.module.scss";

interface Props {
  file: string;
  onClick: () => void;
}

const UploadedFileButton: React.FC<Props> = (props) => {
  return (
    <div className={styles.fileEditContainer}>
      <a className={styles.fileName} href={props.file}>
        {props.file.split("img/")[1]}
      </a>
      <button
        type="button"
        className={styles.deleteButton}
        onClick={props.onClick}
      >
        x
      </button>
    </div>
  );
};

export default UploadedFileButton;

import React from "react";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter/filter.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/filter/search.svg";
import styles from "./styles.module.scss";

interface Props {
  searchValue: string;
  onSearchChange: (value: string) => void;
  onPressAdd?: () => void | null;
  onPressModal?: () => void;
  addButtonTitle?: string;
}

const FilterBlock: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <input
          type="text"
          className={styles.input}
          placeholder="Пошук"
          value={props.searchValue}
          onChange={(e) => props.onSearchChange(e.currentTarget.value)}
        />
        <SearchIcon className={styles.icon} />
      </div>
      {props.onPressModal && (
        <button className={styles.filterButton} onClick={props.onPressModal}>
          <FilterIcon />
          <span className={styles.span}>Фільтр</span>
        </button>
      )}
      {props.onPressAdd && (
        <button className={styles.addButton} onClick={props.onPressAdd}>
          {props.addButtonTitle}
        </button>
      )}
    </div>
  );
};

export default FilterBlock;

import React, { ReactNode, useState } from "react";
import { ReactComponent as ChevronIcon } from "../../../../assets/icons/nav/chevron.svg";
import styles from "./styles.module.scss";

interface Props {
  blockName: string;
  children: ReactNode;
}

const NavigationBlock: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const onClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <button className={styles.button} onClick={onClick}>
        <span className={styles.buttonText}>{props.blockName}</span>
        <ChevronIcon
          className={`${
            isOpen
              ? `${styles.chevronToggle} ${styles.chevron}`
              : styles.chevron
          }`}
        />
      </button>
      <div
        className={`${styles.linksContainer} ${!isOpen ? styles.isClose : ""}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default NavigationBlock;

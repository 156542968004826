import * as Yup from "yup";
import { validationMessage } from "../translation";

export interface ValidationResponse {
  success: boolean;
  error?: string;
}

const emailValidationSchema = Yup.object({
  email: Yup.string()
    .email(validationMessage.invalidEmail)
    .required(validationMessage.requiredField),
});

export async function validateEmail(
  email: string
): Promise<ValidationResponse> {
  try {
    await emailValidationSchema.validate({ email });
    return { success: true };
  } catch (e: any) {
    return { success: false, error: e.message };
  }
}

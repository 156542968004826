import React, { useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { ReactComponent as OrderIcon } from "../../../../assets/icons/modal/order.svg";
import { IRent, IRentUpdateDto } from "../../../../types/rent.type";
import { formatDate } from "../../../../utils/formatDate";
import DateInput from "../../../inputs/DateInput/DateInput";
import ModalTitle from "../../ModalTitle/ModalTitle";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";

interface Props {
  order: IRent;
  onClose: () => void;
  onApply: () => void;
}

const EditOrderForm: React.FC<Props> = (props) => {
  const [name, setName] = useState<string>(props.order.name);
  const [note, setNote] = useState<string>(props.order.note);
  const [paidTo, setPaidTo] = useState<Date | null>(props.order.paidTo);

  const onClickApply = async () => {
    try {
      if (paidTo) {
        const body: IRentUpdateDto = {
          name,
          note,
          paidTo,
        };
        const response = await authApiService.updateRent(props.order.id, body);

        if (response) {
          props.onApply();
          props.onClose();
        }
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };
  return (
    <>
      <ModalTitle
        onClose={props.onClose}
        title="Редугайте додаткову інформацію про оренду"
        subTitle='Змінить назву або нотатку до оренди та натисніть кнопку "Зберегти"'
        isLittleMargin={true}
      >
        <OrderIcon />
      </ModalTitle>
      <div className={styles.container}>
        <form className={styles.form}>
          {/* <EditInput
            title="Назва"
            value={name}
            setValue={(value) => setName(value)}
            isRequired
          /> */}
          <ViewInput title="Імʼя клієнта" value={props.order.client.fullName} />
          <ViewInput title="Контакт клієнта" value={props.order.client.phone} />
          <ViewInput title="Номер байка" value={props.order.bike.number} />
          <div className={styles.flex}>
            <ViewInput
              value={formatDate(props.order.dateFrom)}
              title="Оренда з"
            />
            <ViewInput
              value={formatDate(props.order.dateTo)}
              title="Оренда до"
            />
          </div>
          <ViewInput value={props.order.cost} title="Вартіть оренди (грн)" />
          <DateInput
            title="Оплачено до"
            minDate={props.order.paidTo ?? undefined}
            date={paidTo ?? new Date()}
            maxDate={props.order.dateTo}
            onChange={(value) => setPaidTo(value)}
          />
          <EditInput
            title="Нотатка"
            value={note}
            setValue={(value) => setNote(value)}
            isRequired={false}
            isBig
          />
        </form>
        <ModalBottomButtons
          onPressApply={onClickApply}
          applyTitle="Зберегти"
          onPressBack={props.onClose}
          backTitle="Назад"
        />
      </div>
    </>
  );
};

export default EditOrderForm;

import React from "react";
import { IUser } from "../../api/api.interface";
import { ReactComponent as EditIcon } from "../../assets/icons/management/edit.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/management/info.svg";
import { getRole } from "../../utils/getRole";
import styles from "./styles.module.scss";

interface Props {
  users: IUser[];
  callBackInfo: (bool: boolean) => void;
  callBackEdit: (user: IUser) => void;
}

const ManagementTable: React.FC<Props> = (props) => {
  return (
    <table className={styles.customTable}>
      <thead>
        <tr>
          <th>Ім'я працівника</th>
          <th>Статус</th>
          <th>Роль</th>
          <th>Дата додавання</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.users &&
          props.users?.length > 0 &&
          props.users.map((user, index) => (
            <tr key={user.id}>
              <td>
                <div className={styles.userInfo}>
                  {user.avatarUrl ? (
                    <img
                      src={user.avatarUrl}
                      className={styles.avatar}
                      alt="Avatar"
                    />
                  ) : (
                    <div className={styles.avatar}></div>
                  )}
                  <div className={styles.nameContainer}>
                    <span className={styles.name}>{user.fullName}</span>
                    <span className={styles.email}>{user.email}</span>
                  </div>
                </div>
              </td>
              <td>
                {user.status === "registered" ? (
                  <div className={`${styles.status} ${styles.registered}`}>
                    <span>Зареєстрований</span>
                  </div>
                ) : (
                  <div className={`${styles.status} ${styles.notRegistered}`}>
                    <span>Незареєстрований</span>
                  </div>
                )}
              </td>
              <td className={styles.role}>{getRole(user.role)}</td>
              <td className={styles.role}>
                {new Date(user.createdAt).toLocaleDateString()}
              </td>
              <td>
                <div className={styles.actions}>
                  <button
                    className={styles.editBtn}
                    onClick={() => {
                      props.callBackInfo(false);
                      props.callBackEdit(user);
                    }}
                  >
                    <EditIcon />
                  </button>
                  <button
                    className={styles.infoBtn}
                    onClick={() => {
                      props.callBackInfo(true);
                      props.callBackEdit(user);
                    }}
                  >
                    <InfoIcon />
                  </button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ManagementTable;

import React from "react";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/management/checkbox/checked.svg";
import { ReactComponent as EmptyIcon } from "../../../assets/icons/management/checkbox/empty.svg";
import styles from "./styles.module.scss";

interface Props {
  name: string;
  label: string;
  isChecked: boolean;
  description: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const CustomRadioButton: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <input
          name={props.name}
          value={props.value}
          type="radio"
          id={props.value}
          checked={props.isChecked}
          className={styles.input}
          onChange={props.onChange}
        />
        <label htmlFor={props.value} className={styles.label}>
          <span className={styles.icon}>
            {props.isChecked ? <CheckedIcon /> : <EmptyIcon />}
          </span>
          <span>{props.label}</span>
        </label>
      </div>
      <span className={styles.description}>{props.description}</span>
    </div>
  );
};

export default CustomRadioButton;

import { uk } from "date-fns/locale";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";
registerLocale("uk", uk);

interface Props {
  date: Date | null;
  onChange: (value: Date | null) => void;
  title?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DateInput: React.FC<Props> = (props) => {
  return (
    <div className={styles.subContainer}>
      <div className={styles.titleContainer}>
        {props.title && <span className={styles.title}>{props.title}</span>}
      </div>
      <DatePicker
        selected={props.date}
        onChange={(value) => props.onChange(value)}
        className={styles.dateInput}
        onFocus={(e) => e.target.blur()}
        dateFormat={"dd-MM-yyyy"}
        minDate={props.minDate}
        maxDate={props.maxDate}
        locale="uk"
      />
    </div>
  );
};

export default DateInput;

import React from "react";
import { IHistory } from "../../../../types/history.type";
import { formatDate, formatTime } from "../../../../utils/formatDate";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";

interface Props {
  history: IHistory;
  onClose: () => void;
}

const ViewHistoryForm: React.FC<Props> = (props) => {
  return (
    <div>
      <div className={styles.viewForm}>
        {props.history.bikeNumber ? (
          <div className={styles.providerContainer}>
            <ViewInput title="Номер байка" value={props.history.bikeNumber} />
            <ViewInput title="Назва байка" value={props.history.bikeName} />
          </div>
        ) : (
          <ViewInput title="Списання" value="Списано зі складу" />
        )}
        <ViewInput
          title="Дата"
          value={`${formatTime(props.history.createdAt)} ${formatDate(
            props.history.createdAt
          )}`}
        />
        <ViewInput
          title="Працівник який списав"
          value={props.history.user.fullName}
        />
        {props.history.items.map((item) => (
          <div className={styles.providerContainer} key={item.itemName}>
            <ViewInput title="Запчастина" value={item.itemName} />
            <ViewInput title="Кількість" value={item.quantity.toString()} />
          </div>
        ))}
        {props.history.comment && (
          <ViewInput title="Коментар" value={props.history.comment} isBig />
        )}
      </div>

      <ModalBottomButtons
        backTitle="Назад"
        onPressBack={props.onClose}
        applyTitle="Розпочати роботу"
        // onPressApply={props.onPressEdit}
      />
    </div>
  );
};

export default ViewHistoryForm;

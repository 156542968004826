import { OrderStatus } from "../types/order.type";

export const getOrderStatus = (status: string): string => {
  switch (status) {
    case OrderStatus.NEW_ORDER:
      return "Новий";
    case OrderStatus.IN_PROGRESS:
      return "В роботі";
    case OrderStatus.CANCELED:
      return "Скасований";
    case OrderStatus.COMPLETED:
      return "Виконаний";
    case OrderStatus.SHIPPED:
      return "Відвантажений";
    default:
      return "Невідомо";
  }
};

import React from "react";
import { ReactComponent as EditIcon } from "../../assets/icons/management/edit.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/management/info.svg";
import { useUser } from "../../providers/UserProvider";
import { IResource } from "../../types/resources.type";
import styles from "./styles.module.scss";

interface Props {
  resources: IResource[];
  callBackInfo: (bool: boolean) => void;
  callBackEdit: (user: IResource) => void;
}

const ResourceTable: React.FC<Props> = (props) => {
  const userLog = useUser();
  return (
    <table className={styles.customTable}>
      <thead>
        <tr>
          <th>Назва запчастини</th>
          <th>Вартість</th>
          <th>Кількість</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.resources &&
          props.resources?.length > 0 &&
          props.resources
            .filter((item) => item.quantity > 0)
            .map((user, index) => (
              <tr key={user.id}>
                <td>
                  <div className={styles.userInfo}>
                    <div className={styles.nameContainer}>
                      <span className={styles.name}>{user.name}</span>
                    </div>
                  </div>
                </td>
                <td className={styles.role}>{user.price} грн.</td>

                <td className={styles.role}>{user.quantity} од.</td>
                <td>
                  <div className={styles.actions}>
                    <button
                      className={styles.editBtn}
                      onClick={() => {
                        props.callBackInfo(false);
                        props.callBackEdit(user);
                      }}
                    >
                      <EditIcon />
                    </button>
                    <button
                      className={styles.infoBtn}
                      onClick={() => {
                        props.callBackInfo(true);
                        props.callBackEdit(user);
                      }}
                    >
                      <InfoIcon />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  );
};

export default ResourceTable;

import React from "react";
import { ReactComponent as InfoIcon } from "../../assets/icons/management/info.svg";
import { IHistory } from "../../types/history.type";
import { formatDate, formatTime } from "../../utils/formatDate";
import styles from "./styles.module.scss";

interface Props {
  history: IHistory[];
  callBackInfo: (history: IHistory) => void;
}

const HistoryTable: React.FC<Props> = (props) => {
  return (
    <table className={styles.customTable}>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Байк</th>
          <th>Запчастини</th>
          <th>Коментар</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.history &&
          props.history.map((user, index) => (
            <tr key={user.id}>
              <td>
                <div className={styles.userInfo}>
                  <div className={styles.nameContainer}>
                    <span className={styles.email}>
                      {formatTime(user.createdAt)} {formatDate(user.createdAt)}
                    </span>
                  </div>
                </div>
              </td>
              <td className={styles.role}>
                <div className={styles.nameContainer}>
                  {user.bikeNumber ? (
                    <>
                      <span className={styles.name}>{user.bikeNumber}</span>
                      <span className={styles.email}>{user.bikeName}</span>
                    </>
                  ) : (
                    <span className={styles.email}>Списано зі складу</span>
                  )}
                </div>
              </td>
              <td className={styles.role}>
                <div className={styles.nameContainer}>
                  {user.items.map((item) => (
                    <span className={styles.email} key={item.itemName}>
                      {item.itemName} - {item.quantity}
                    </span>
                  ))}
                </div>
              </td>
              <td>
                <span className={styles.email}>
                  {user.comment ? user.comment : "Коментар відсутній"}
                </span>
              </td>
              <td>
                <div className={styles.actions}>
                  <button
                    className={styles.infoBtn}
                    onClick={() => {
                      props.callBackInfo(user);
                    }}
                  >
                    <InfoIcon />
                  </button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default HistoryTable;

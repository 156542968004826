import React, { ReactNode } from "react";
import styles from "./styles.module.scss";

interface Props {
  value: string;
  setValue: (value: string) => void;
  isRequired: boolean;
  title?: string;
  children?: ReactNode;
  isBig?: boolean;
}

const EditInput: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      {props.title && (
        <label htmlFor={props.title} className={styles.label}>
          {props.title}
          {props.isRequired && <span className={styles.require}> *</span>}
        </label>
      )}
      {props.isBig ? (
        <textarea
          style={{ resize: "none", height: "96px" }}
          className={`${styles.input} ${styles.bigInput} ${
            props.children ? "" : styles.inputWithoutIcon
          }`}
          id={props.title}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
        />
      ) : (
        <input
          type="text"
          className={`${styles.input} ${props.isBig ? styles.bigInput : ""} ${
            props.children ? "" : styles.inputWithoutIcon
          }`}
          id={props.title}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
        />
      )}
      {props.children && <div className={styles.icon}>{props.children}</div>}
    </div>
  );
};

export default EditInput;

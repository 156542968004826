import React, { useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { useUser } from "../../../../providers/UserProvider";
import { IBike } from "../../../../types/bikes.type";
import { IItemWriteOfToBike } from "../../../../types/items.type";
import { IResource } from "../../../../types/resources.type";
import { RoleType } from "../../../../types/role.type";
import { ISpendingOff } from "../../../../types/spending.type";
import { resourceOffSelector } from "../../../../utils/createSelector";
import SmallWhiteButton from "../../../buttons/WhiteButton/SmallWhiteButton";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import SelectInput, { SelectOption } from "../inputs/SelectInput/SelectInput";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";
interface Props {
  resource: IResource;
  onClose: () => void;
  onPressEdit: () => void;
  onApply: () => void;
  bikes: IBike[];
}

const initSelect: SelectOption = {
  value: "none",
  label: "Оберіть куди списати запчастину",
};

const ViewResourceForm: React.FC<Props> = (props) => {
  const user = useUser();
  const [isOff, setIsOff] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<string>("");
  const [selectOff, setSelectOff] = useState<SelectOption>(initSelect);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");

  const onChangeQuantity = (value: string) => {
    const numberValue = Number(value);
    if (isNaN(numberValue)) {
      toast.error("Поле очікує числове значення");
      return;
    }

    if (numberValue > props.resource.quantity) {
      toast.error("Не можливо списати більше ніж є на складі");
      return;
    }

    setQuantity(value);
  };

  const onClickWriteOff = async () => {
    if (!quantity || selectOff.value === "none") {
      toast.error("Заповніть поля");
      return;
    }
    setIsDisabled(true);
    try {
      if (user.user) {
        let response;
        if (selectOff.value === "off") {
          const body: ISpendingOff = {
            note: "",
            items: [
              {
                itemId: props.resource.id,
                quantity: Number(quantity),
              },
            ],
            userId: user.user.id,
          };
          response = await authApiService.spendingOff(body);
        } else {
          const body: IItemWriteOfToBike = {
            bikeId: selectOff.value,
            note: "",
            items: [
              {
                itemId: props.resource.id,
                quantity: Number(quantity),
              },
            ],
            userId: user.user.id,
          };
          response = await authApiService.writeOfItemsToBike(body);
        }
        if (response) {
          toast.success("Списано");
          props.onApply();
          setIsOff(false);

          props.resource.quantity -= Number(quantity);
          setQuantity("");
          setSelectOff(initSelect);
        }
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
    setIsDisabled(false);
  };

  return (
    <>
      {isOff ? (
        <div>
          <div className={styles.form}>
            <SelectInput
              values={resourceOffSelector(props.bikes)}
              value={selectOff}
              onChange={(value) => setSelectOff(value)}
              title="Списати"
            />
            <div className={styles.viewContainer}>
              <ViewInput
                title="Доступо"
                value={props.resource.quantity.toString()}
              />
              <EditInput
                value={quantity}
                isRequired
                setValue={onChangeQuantity}
                title="Кількість"
              />
            </div>
            <EditInput
              value={note}
              isRequired={false}
              setValue={setNote}
              title="Нотатка"
              isBig
            />
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={() => {
              setIsOff(false);
              setQuantity("");
            }}
            applyTitle="Списати"
            onPressApply={onClickWriteOff}
            isApplyDisabled={isDisabled}
          />
        </div>
      ) : (
        <div>
          <div className={styles.form}>
            <ViewInput title="Назва" value={props.resource.name} />
            <div className={styles.viewContainer}>
              <ViewInput
                title="Вартість"
                value={props.resource.price.toString()}
              />
              <ViewInput
                title="Кількість"
                value={props.resource.quantity.toString()}
              />
            </div>
            {props.resource.description && (
              <ViewInput
                title="Опис"
                value={props.resource.description}
                isBig={true}
              />
            )}
            {user.user?.role === RoleType.ADMIN && (
              <SmallWhiteButton
                onClick={() => setIsOff(true)}
                title="Списати"
              />
            )}
          </div>
          <ModalBottomButtons
            backTitle="Назад"
            onPressBack={props.onClose}
            applyTitle="Редагувати"
            onPressApply={
              user.user?.role === RoleType.ADMIN ? props.onPressEdit : undefined
            }
          />
        </div>
      )}
    </>
  );
};

export default ViewResourceForm;

import React from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  isBigButton?: boolean;
}

const BlueButton: React.FC<Props> = (props) => {
  return (
    <button
      className={`${styles.button} ${props.isBigButton ? styles.big : ""}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.title}
    </button>
  );
};

export default BlueButton;

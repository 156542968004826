import React from "react";
import styles from "./styles.module.scss";

interface Props {
  url: string;
}

const FilesView: React.FC<Props> = (props) => {
  return (
    <a
      className={styles.link}
      href={props.url}
      download
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.url.split("img/")[1]}
    </a>
  );
};

export default FilesView;

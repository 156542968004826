import React, { ReactNode } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/modal/close.svg";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  children?: ReactNode;
  subTitle?: string;
  onClose: () => void;
  isLittleMargin?: boolean;
}

const ModalTitle: React.FC<Props> = (props) => {
  return (
    <div
      className={`${
        props.isLittleMargin ? styles.marginHeader : styles.header
      }`}
    >
      <div className={styles.editTitleContainer}>
        <div className={styles.editTitle}>
          {props.children}
          <span className={styles.title}>{props.title}</span>
        </div>
        <button className={styles.upSideButton} onClick={props.onClose}>
          <CloseIcon />
        </button>
      </div>
      <span className={styles.subTitle}>{props.subTitle}</span>
    </div>
  );
};

export default ModalTitle;

import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  to: string;
  children: ReactNode;
  onClick?: () => void;
}

const NavigationLink: React.FC<Props> = (props) => {
  return (
    <NavLink
      onClick={props.onClick}
      to={props.to}
      className={({ isActive }: { isActive: boolean }) =>
        isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
      }
    >
      {props.children}
      {props.title}
    </NavLink>
  );
};

export default NavigationLink;

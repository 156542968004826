import { uk } from "date-fns/locale";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";
registerLocale("uk", uk);

interface Props {
  start: Date | null;
  onChangeStart: (value: Date | null) => void;
  end: Date | null;
  onChangeEnd: (value: Date | null) => void;
}

const DateRangeInputs: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>Від</span>
          <button
            type="button"
            className={styles.button}
            onClick={() => props.onChangeStart(null)}
          >
            Очистити
          </button>
        </div>
        <DatePicker
          locale="uk"
          selected={props.start}
          onChange={(value) => props.onChangeStart(value)}
          className={styles.dateInput}
          onFocus={(e) => e.target.blur()}
          maxDate={props.end ? props.end : undefined}
          dateFormat={"dd-MM-yyyy"}
        />
      </div>
      <div className={styles.subContainer}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>До</span>
          <button
            type="button"
            className={styles.button}
            onClick={() => props.onChangeEnd(null)}
          >
            Очистити
          </button>
        </div>
        <DatePicker
          locale="uk"
          selected={props.end}
          onChange={(value) => props.onChangeEnd(value)}
          className={styles.dateInput}
          onFocus={(e) => e.target.blur()}
          minDate={props.start ? props.start : undefined}
          dateFormat={"dd-MM-yyyy"}
        />
      </div>
    </div>
  );
};

export default DateRangeInputs;

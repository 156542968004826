import React from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  subTitle: string;
}

const AuthTitle: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{props.title}</h1>
      <h2 className={styles.subTitle}>{props.subTitle}</h2>
    </div>
  );
};

export default AuthTitle;

import React from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { unAuthApiService } from "../../../../api/api";
import AuthTitle from "../../../AuthTitle/AuthTitle";
import CodeForm from "../CodeForm/CodeForm";
import styles from "./styles.module.scss";

const ConfirmCodeEmailForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { email } = location.state;

  if (!email) {
    navigate("/login");
  }

  const confirmEmail = async (code: string, email: string) => {
    try {
      return await unAuthApiService.confirmEmail(code, email);
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const sendCode = async (code: string) => {
    const response = await confirmEmail(code, email);
    if (!response) {
      return;
    }
    console.log(code);
    navigate("/resetPassword", { state: { code, email } });
  };
  return (
    <div className={styles.container}>
      <AuthTitle
        title="Підтвердження електронної пошти"
        subTitle="Будь ласка, введіть код підтвердження, який був відправлений на вашу електронну адресу"
      />
      <div className={styles.inputsContainer}>
        <CodeForm
          onPressApply={sendCode}
          onPressBack={() => {
            navigate("/forgotPassword");
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmCodeEmailForm;

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { authApiService } from "../../api/api";
import { IStatistics } from "../../types/statistics.type";
import styles from "./styles.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const StatisticsPage: React.FC = () => {
  const getStartDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  };
  const [statistics, setStatistics] = useState<IStatistics | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(getStartDate());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const getStatistics = async () => {
    try {
      if (startDate && endDate) {
        startDate.setUTCHours(0, 0, 0, 0);
        endDate.setUTCHours(23, 59, 59, 999);
        const response = await authApiService.getStatistics(
          startDate.toISOString(),
          endDate.toISOString()
        );
        if (response) {
          setStatistics(response);
        }
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const data = {
    labels: [
      `Було в оренді: ${
        statistics?.bikesWithRent ? statistics.bikesWithRent : 0
      }`,
      `Не було в оренді: ${
        statistics?.bikesWithoutRent ? statistics.bikesWithoutRent : 0
      }`,
    ],
    datasets: [
      {
        label: "Замовлення",
        data: [
          statistics?.bikesWithRent ? statistics.bikesWithRent : 0,
          statistics?.bikesWithoutRent ? statistics.bikesWithoutRent : 0,
        ],
        backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 206, 86, 0.5)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 0,
      },
    ],
  };

  useEffect(() => {
    getStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <main className={styles.container}>
      <div className={styles.pie}>
        <p className={styles.name}>Байки</p>
        <p className={styles.email}>Всього: {statistics?.totalBikes} </p>
        <Pie data={data} />
      </div>

      <div className={styles.rightSide}>
        <div className={styles.dateContainer}>
          <div className={styles.titleDateContainer}>
            <span className={styles.email}>Від</span>
            <DatePicker
              selected={startDate}
              onChange={(value) => setStartDate(value)}
              className={styles.dateInput}
              onFocus={(e) => e.target.blur()}
              dateFormat={"dd-MM-yyyy"}
              maxDate={new Date()}
              withPortal
            />
          </div>
          <div className={styles.titleDateContainer}>
            <span className={styles.email}>До</span>
            <DatePicker
              selected={endDate}
              onChange={(value) => setEndDate(value)}
              className={styles.dateInput}
              onFocus={(e) => e.target.blur()}
              dateFormat={"dd-MM-yyyy"}
              minDate={startDate || new Date()}
              maxDate={new Date()}
              withPortal
            />
          </div>
        </div>

        <div className={styles.panel}>
          <p className={styles.email}>Всього оренд</p>
          <p className={styles.name}>{statistics?.totalRentsInPeriod}</p>
        </div>
        <div className={styles.panel}>
          <p className={styles.email}>Байків в простої</p>
          <p className={styles.name}>{statistics?.bikesWithoutRent}</p>
        </div>
        <div className={styles.panel}>
          <p className={styles.email}>Списано запчастин</p>
          <p className={styles.name}>
            {statistics?.totalResourcesUsedInPeriod}
          </p>
        </div>
        <div className={styles.panel}>
          <p className={styles.email}>Загальна вартість списаних запчастин</p>
          <p className={styles.name}>
            {statistics?.totalPriceOfResources} грн.
          </p>
        </div>
      </div>
    </main>
  );
};

export default StatisticsPage;

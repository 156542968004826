import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { authApiService } from "../../../../api/api";
import { ReactComponent as OrderIcon } from "../../../../assets/icons/modal/order.svg";
import { IBikeRents } from "../../../../types/bikes.type";
import { IClient } from "../../../../types/clients.type";
import { IRentCreateDto } from "../../../../types/rent.type";
import { formatDate } from "../../../../utils/formatDate";
import { daysBetween } from "../../../../utils/getDaysBetween";
import FilesView from "../../../FileView/FilesView";
import DateInput from "../../../inputs/DateInput/DateInput";
import DateRangeInputs from "../../../inputs/DateRangeInputs/DateRangeInputs";
import StepsHeader from "../../../StepsHeader/StepsHeader";
import ModalTitle from "../../ModalTitle/ModalTitle";
import ModalBottomButtons from "../buttons/ModakBottomButtons/ModalBottomButtons";
import EditInput from "../inputs/EditInput/EditInput";
import SelectInput, { SelectOption } from "../inputs/SelectInput/SelectInput";
import ViewInput from "../inputs/ViewInput/ViewInput";
import styles from "./styles.module.scss";

interface Props {
  clients: IClient[];
  onClose: () => void;
  onApply: () => void;
}

const AddOrderForm: React.FC<Props> = (props) => {
  const [step, setStep] = useState<number>(1);
  const [dateStart, setDateStart] = useState<Date | null>(new Date());
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [bikes, setBikes] = useState<IBikeRents[]>([]);
  const [selectBike, setSelectBike] = useState<SelectOption>({
    value: "none",
    label: "Оберіть байк",
  });
  const [bike, setBike] = useState<IBikeRents | null>(null);
  const [selectClient, setSelectClient] = useState<SelectOption>({
    value: "none",
    label: "Оберіть клієнта",
  });
  const [client, setClient] = useState<IClient | null>(null);
  const [name, setName] = useState<string>("Rent");
  const [note, setNote] = useState<string>("");
  const [cost, setCost] = useState<string>("");
  const [paidTo, setPaidTo] = useState<Date>(new Date());

  useEffect(() => {
    if (dateStart) {
      const date = new Date(dateStart);
      date.setDate(dateStart.getDate() + 7);
      setPaidTo(date);
    }
  }, [dateStart]);

  const getAvailableBikes = async (
    dateFrom: Date | null,
    dateTo: Date | null
  ) => {
    try {
      if (dateFrom && dateTo) {
        dateFrom.setUTCHours(0, 0, 0, 0);
        dateTo.setUTCHours(23, 59, 59, 999);
        const response = await authApiService.getAvaliablesBikes(
          // formatDateToFetch(dateFrom),
          dateFrom.toISOString(),
          dateTo.toISOString()
          // formatDateToFetch(dateTo)
        );

        if (response) {
          return response;
        }
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const onClickFirstStep = async () => {
    if (!dateStart || !dateEnd) {
      toast.error("Оберіть діапазон оренди");
      return;
    }
    dateStart.setUTCHours(0, 0, 0, 0);
    dateEnd.setUTCHours(23, 59, 59, 999);
    if (daysBetween(dateStart, dateEnd) < 9) {
      toast.error("Мінімальний час оренди - 7 днів");
      return;
    }
    const response = await getAvailableBikes(dateStart, dateEnd);
    if (response) {
      setBikes(response);
      setStep(2);
    }
  };

  const onClickSecondStep = () => {
    if (selectBike.value === "none") {
      toast.error("Оберіть байк для оренди");
      return;
    }
    if (!cost) {
      toast.error("Введіть ціну оренди");
      return;
    }

    setStep(3);
  };

  const onClickCreateRent = async () => {
    try {
      if (
        dateEnd &&
        dateStart &&
        bike &&
        cost &&
        paidTo &&
        selectClient.value !== "none"
      ) {
        const body: IRentCreateDto = {
          name,
          dateFrom: dateStart,
          dateTo: dateEnd,
          clientId: selectClient.value,
          bikeId: selectBike.value,
          note,
          cost: Number(cost),
          paidTo,
        };

        const response = await authApiService.createRent(body);

        if (response) {
          props.onApply();
          props.onClose();
        }
      } else {
        toast.error("Заповніть всі поля");
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    const findBike = bikes.find((item) => item.id === selectBike.value);
    if (findBike) {
      setBike(findBike);
      // if (dateStart && dateEnd) {
      //   setCost(
      //     (Number(findBike.price) * daysBetween(dateStart, dateEnd)).toString()
      //   );
      // }
    }
  }, [selectBike, bikes, dateStart, dateEnd]);

  useEffect(() => {
    const findClient = props.clients.find(
      (item) => item.id === selectClient.value
    );
    if (findClient) {
      setClient(findClient);
    }
  }, [selectClient, props.clients]);

  return (
    <>
      <ModalTitle
        onClose={props.onClose}
        title={"Додати оренду"}
        subTitle={
          "Додайте діапазон оренди, беріть клієнта та вільний байк для оренди"
        }
        isLittleMargin={true}
      >
        <OrderIcon />
      </ModalTitle>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <StepsHeader
            step={step}
            fitstStepTitle="Дати"
            secondStepTitle="Байк"
            thirdStepTitle="Клієнт"
          />
        </div>
        {step === 1 ? (
          <>
            <form className={styles.form}>
              <DateRangeInputs
                start={dateStart}
                onChangeStart={(value) => setDateStart(value)}
                end={dateEnd}
                onChangeEnd={(value) => setDateEnd(value)}
              />
            </form>
            <ModalBottomButtons
              onPressApply={onClickFirstStep}
              applyTitle="Наступний крок"
              isNextIcon={true}
              onPressBack={props.onClose}
              backTitle="Назад"
            />
          </>
        ) : step === 2 ? (
          <>
            <form className={styles.form}>
              <SelectInput
                value={selectBike}
                values={bikes.map((item) => ({
                  value: item.id,
                  label: `${item.number} ${item.name}`,
                }))}
                onChange={(value) => setSelectBike(value)}
              />

              <div className={styles.flex}>
                {dateStart && (
                  <ViewInput title="З" value={formatDate(dateStart)} />
                )}
                {dateEnd && (
                  <ViewInput title="До (включно)" value={formatDate(dateEnd)} />
                )}
              </div>
              {bike && (
                <>
                  <div className={styles.flex}>
                    <ViewInput title="Номер" value={bike.number} />
                    <ViewInput title="Назва" value={bike.name} />
                  </div>
                  {/* <div className={styles.flex}>
                    <ViewInput title="Вартість (за день)" value={bike.price} />
                    {dateStart && dateEnd && (
                      <ViewInput
                        title="Рекомендована вартість"
                        value={(
                          Number(bike.price) * daysBetween(dateStart, dateEnd)
                        ).toString()}
                      />
                    )}
                  </div> */}
                  <EditInput
                    title="Кінцева вартість"
                    value={cost}
                    setValue={(value) => {
                      if (isNaN(Number(value))) {
                        toast.error("Поле очікує числове значення");
                        return;
                      }
                      setCost(value);
                    }}
                    isRequired
                  />
                  {bike.note && (
                    <ViewInput
                      title="Нотатка до байку"
                      value={bike.note}
                      isBig
                    />
                  )}
                </>
              )}
            </form>
            <ModalBottomButtons
              onPressApply={onClickSecondStep}
              applyTitle="Наступний крок"
              isNextIcon={true}
              onPressBack={() => setStep((prevState) => (prevState -= 1))}
              backTitle="Назад"
            />
          </>
        ) : (
          <>
            <form className={styles.form}>
              <SelectInput
                value={selectClient}
                values={props.clients.map((item) => ({
                  value: item.id,
                  label: `${item.fullName} ${item.phone}`,
                }))}
                onChange={(value) => setSelectClient(value)}
              />
              {/* <EditInput
                value={name}
                setValue={(value) => setName(value)}
                title="Назва оренди"
                isRequired={false}
              /> */}
              <div className={styles.flex}>
                {bike && <ViewInput title="Номер байка" value={bike.number} />}
                {bike && <ViewInput title="Назва" value={bike.name} />}
              </div>
              <div className={styles.flex}>
                {dateStart && dateEnd && (
                  <ViewInput
                    title="Дати"
                    value={`${formatDate(dateStart)} - ${formatDate(dateEnd)}`}
                  />
                )}
                {bike && dateStart && dateEnd && (
                  <ViewInput title="Вартість оренди" value={cost} />
                )}
              </div>
              {client && (
                <>
                  <ViewInput title="Імʼя клієнта" value={client.fullName} />
                  <ViewInput title="Номер телефону" value={client.phone} />
                  {client.adress && (
                    <ViewInput title="Адреса" value={client.adress} />
                  )}
                  {client.note && (
                    <ViewInput
                      title="Нотатка по клієнту"
                      value={client.note}
                      isBig
                    />
                  )}
                  {client.files && client.files.length > 0 && (
                    <div className={styles.filesContainer}>
                      <span className={styles.title}>Файли клієнта</span>
                      {client.files.map((file) => (
                        <FilesView url={file} key={file} />
                      ))}
                    </div>
                  )}
                </>
              )}
              <DateInput
                date={paidTo}
                onChange={(value) => {
                  if (value) {
                    setPaidTo(value);
                  }
                }}
                title="Оплачено до"
                minDate={dateStart ?? undefined}
              />
              <EditInput
                value={note}
                setValue={(value) => setNote(value)}
                title="Нотатка"
                isBig
                isRequired={false}
              />
            </form>
            <ModalBottomButtons
              onPressApply={onClickCreateRent}
              applyTitle="Створити оренду"
              onPressBack={() => setStep((prevState) => (prevState -= 1))}
              backTitle="Назад"
            />
          </>
        )}
      </div>
    </>
  );
};

export default AddOrderForm;

// userContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

export interface IUser {
  id: string;
  fullName: string;
  email: string;
  phone: string;
  avatarUrl: string;
  createdAt: Date;
  updatedAt: Date;
  role: string;
}

// Створення інтерфейсу для контексту користувача
interface IUserContext {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
}

// Ініціалізація контексту
const UserContext = createContext<IUserContext | undefined>(undefined);

// Провайдер контексту
export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Хук для використання контексту
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

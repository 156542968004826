import React, { ReactNode } from "react";
import styles from "./styles.module.scss";

interface Props {
  onClick: () => void;
  title: string;
  children?: ReactNode;
  disabled?: boolean;
  isLink?: boolean;
}

const GreenButton: React.FC<Props> = (props) => {
  return (
    <button
      className={`${styles.button} ${props.isLink ? styles.link : ""}`}
      onClick={props.onClick}
      disabled={props.disabled}
      type="button"
    >
      <span>{props.title}</span>
      {props.children}
    </button>
  );
};

export default GreenButton;

import React, { useEffect } from "react";
import { IClient } from "../../../types/clients.type";
import DefaultModal from "../DefaultModal/DefaultModal";
import AddOrderForm from "../forms/AddOrderForm/AddOrderForm";
import styles from "./styles.module.scss";

interface Props {
  onClose: () => void;
  onApply: () => void;
  clients: IClient[];
}

const AddOrderModal: React.FC<Props> = (props) => {
  const onApply = () => {
    props.onApply();
    props.onClose();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  return (
    <DefaultModal>
      <div className={styles.container}>
        <AddOrderForm
          onApply={onApply}
          onClose={props.onClose}
          clients={props.clients}
        />
      </div>
    </DefaultModal>
  );
};

export default AddOrderModal;
